import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";

const WhatIsServiceComponentSiroc = ({ what, objective, hasObjective = false, imageUrl, fullHeight = false }) => {
   return (
      <Stack
         direction={{ xs: "column-reverse", md: hasObjective ? "row-reverse" : "row" }}
         sx={{ bgcolor: "#46566A", display: "flex", minHeight: "600px" }}
      >
         <Stack direction={"column"} flex={1}>
            {hasObjective ? (
               <Box
                  flex={1}
                  pt={4}
                  pb={{ xs: 8, sm: 8 }}
                  px={{ xs: 2, sm: 5, lg: 10 }}
                  bgcolor={"#748190"}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
               >
                  <Typography
                     fontFamily={"bold"}
                     color={"white"}
                     lineHeight={1} // Ajusta el interlineado aquí
                     sx={{
                        mt: { md: 5, lg: 15 },
                        mb: 4,
                        px: { xs: 2, md: 2, lg: 1 },
                        fontSize: { xs: "26px", md: "31px" },
                        letterSpacing: { lg: 0.01 },
                        lineHeight: 1.2,
                     }}
                  >
                     ¿Qué es y por qué se debe cumplir con él?
                  </Typography>
                  <Typography color={"white"} textAlign={"justify"} sx={{ px: { xs: 2, sm: 4, md: 2, lg: 1 } }}>
                     {objective}
                  </Typography>
                  <Box
                     width={{ xs: "100%", md: "100%", lg: "20%" }}
                     height={{ xs: "910px", sm: "630px", md: "800px", lg: "760px" }}
                     position={"absolute"}
                     right={{ xs: "0%", lg: "0%" }}
                     sx={{
                        backgroundImage:
                           "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/gobierno-corporativo/fondos/Fondo-Seccion-3-derecho.png)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: { xs: "300px 300px", lg: "350px 350px" },
                        backgroundPosition: "bottom right",
                        opacity: 0.15,
                     }}
                  ></Box>
               </Box>
            ) : undefined}
         </Stack>
         <Box flex={1}>
            <Box height={"100%"} px={0} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
               {!hasObjective ? undefined : (
                  <Box
                     width={{ xs: "100%", lg: "20%" }}
                     height={{ xs: "600px", md: "400px", lg: "675px" }}
                     position={"absolute"}
                     right={{ xs: "0", lg: "50%" }}
                  />
               )}
               <Box
                  sx={{
                     width: "100%",
                     height: fullHeight ? "100%" : { xs: "350px", md: "550px", lg: "500px" },
                     backgroundImage: imageUrl,
                     backgroundSize: "cover",
                     backgroundRepeat: "no-repeat",
                     backgroundPosition: { xs: "center center", md: "center center", lg: "top center" },
                  }}
               />
               <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  py={"5%"}
                  sx={{ px: { xs: 4, sm: 8, md: 5, lg: 10 }, py: { xs: "50px" } }}
               >
                  <Typography
                     color={"white"}
                     textAlign={"justify"}
                     sx={
                        {
                           // mt: { lg: 2 }, mb: { lg: 7 }
                        }
                     }
                  >
                     {what}
                  </Typography>
               </Box>
            </Box>
         </Box>
      </Stack>
   );
};

WhatIsServiceComponentSiroc.propTypes = {
   what: PropTypes.string.isRequired,
   why: PropTypes.string,
   objective: PropTypes.string,
   hasObjective: PropTypes.bool,
   imageUrl: PropTypes.string,
   fullHeight: PropTypes.bool,
};

export default WhatIsServiceComponentSiroc;
