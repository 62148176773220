import { Box, Divider, Stack } from "@mui/material";
import { VisibleElement } from "../lib/hooks/VisibleElement";
import React, { useRef, useState } from "react";
import ReactPlayer from "react-player/lib";
import PropTypes from "prop-types";
import { AnimationOnScroll } from "react-animation-on-scroll";

const VideoContainerAbout = ({
   videoUrl,
   direction = "row",

   alternComponent,
   videoPlayerProps,
}) => {
   const videoIsVisible = useRef(null);
   const [isPlaying, setIsPlaying] = useState(false);
   const portview = videoIsVisible !== null ? VisibleElement(videoIsVisible, `-180px`) : false;
   return (
      <Stack direction={"column"} spacing={2}>
         <Stack
            direction={{ xs: direction === "row" ? "column" : "column-reverse", md: direction }}
            spacing={5}
            py={{ xs: 4, sm: 4, md: 5 }}
            sx={{
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               px: { sm: 8, md: 0, lg: "5vw" },
            }}
         >
            <Stack direction={"column"} alignItems={"center"} sx={{ pt: { md: 10, lg: 12 } }} spacing={1} flex={1}>
               <Box ref={videoIsVisible} {...videoPlayerProps}>
                  <ReactPlayer
                     playing={isPlaying && portview}
                     muted
                     onReady={() => setIsPlaying(true)}
                     url={videoUrl}
                     controls
                     height={"100%"}
                     width={"100%"}
                  />
                  {/* <Box overflow={"hidden"}>
                     <AnimationOnScroll
                        animateIn="animate__slideInLeft"
                        animateOnce
                        animatePreScroll={false}
                        duration={0.75}
                        initiallyVisible
                     >
                        <Divider sx={{ borderBottomWidth: 3, bgcolor: "#8A95A1", mt: 0, width: "100%" }} />
                     </AnimationOnScroll>
                  </Box> */}
               </Box>
            </Stack>
            <Box flex={1}>{alternComponent}</Box>
         </Stack>
      </Stack>
   );
};

VideoContainerAbout.propTypes = {
   videoUrl: PropTypes.string.isRequired,
   direction: PropTypes.string.isRequired,

   subComponent: React.Component,
   alternComponent: React.Component,
   videoPlayerProps: PropTypes.object,
};

export default VideoContainerAbout;
