import { ThemeConfig } from "../config/theme.config";
import ScrollToTop from "../wrapper/scrollToTop";
import {
   Box,
   Button,
   Collapse,
   Stack,
   Typography,
   Grid,
   IconButton,
   Table,
   TableBody,
   TableRow,
   TableCell,
   TableHead,
} from "@mui/material";
import { Footer, NavBar } from "../components";
import ServiceTitleSection from "../components/ServiceTitleSection";
import { descriptionZone, imageZone } from "./ServicesScreen";
import VideoContainer from "../components/VideoContainer";
//import VideoContainer from "../components/VideoContainerCustom";
import ImageContainer from "../components/ImageContainer";
import { StyledAccordionComponent } from "../components/StyledAccordion/styledAccordionComponent";
//import { StyledAccordionComponent } from "../components/StyledAccordion/styledAccordionComponentCustom";
import { BeneficialOwnerBodyContent } from "../const/StyledAccordionContent";
import { BeneficialOwnerBenefits } from "../const/ServicesBenefits";
import { ScrollToTopButton } from "../components/scrollToTopButton";
//import WhatIsServiceComponent from "../components/WhatIsServiceComponent";
import WhatIsServiceComponent from "../components/WhatIsServiceComponentCustom";
import { QuestionAccordion } from "../components/questionAccordion";
import { BeneficialOwnerQuestions } from "../const/AccordionQuestions";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContactConsultantButton from "../components/contactConsultantButton";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export const BeneficialOwner = () => {
   //cambios
   const styledAccContent = {
      details: (
         <Typography textAlign={"justify"} key={"mainTitleContent"}>
            Capture al Beneficiario Controlador de su empresa, o de la cadena de titularidad o de control, en el
            supuesto que sea una persona moral, seleccionado la figura jurídica en el que se encuentra para mayor
            facilidad de gestión.
         </Typography>
      ),
      videoUrl:
         "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/beneficiarioControlador/videos/1.+Identificacion+y+registro+de+beneficiario+controlador.mp4",
   };

   const [selectedText, setSelectedText] = useState(styledAccContent.details);
   const [videoContainerUrl, setVideoContainerUrl] = useState(styledAccContent.videoUrl);

   //cambios
   const [showContent, setShowContent] = useState(false);
   const toggleContent = () => {
      setShowContent(!showContent);
   };
   const navigate = useNavigate();

   return (
      <ThemeConfig>
         <ScrollToTop>
            <NavBar />
            <Box position={"relative"} zIndex={1} sx={{ mb: { md: -8 }, mt: -14 }} pb={{ md: 5, lg: "0 " }}>
               <Box position={"absolute"} top={0} width={"100%"} zIndex={-1}>
                  <Box
                     sx={{
                        backgroundImage:
                           "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/beneficiarioControlador/hero-bg.png)",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        height: { xs: "450px", md: "615px", lg: "700px" },
                     }}
                  />
               </Box>
               <Box sx={{ pt: { xs: "130px", sm: "150px", md: "100px", lg: "110px" }, pb: { xs: 16, sm: 15 } }}>
                  <ServiceTitleSection
                     title={"Beneficiario Controlador"}
                     phrase={"Identificación y gestión para un control total"}
                     icon={
                        "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/iconos/ICONOS+de+m%C3%B3dulos+LECOSY_Beneficiario+Controlador-08.svg)"
                     }
                  />
               </Box>
            </Box>

            <Box
               my={{ xs: -12, sm: -14, md: -14, lg: -25 }}
               position={"relative"}
               bgcolor={"transparent"}
               zIndex={2}
               sx={{
                  backgroundImage: `url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/curvedBg.png)`,
                  backgroundSize: { md: "0% 0%", lg: "100% 135%" },
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  px: { sm: 4, md: 0 },
                  pt: { md: 0, lg: 0 },
               }}
            >
               <Box px={{ xs: 4, sm: 6, md: 10 }} pt={{ sm: 0, md: 8 }}>
                  <ImageContainer
                     imageUrl={
                        "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/beneficiarioControlador/section1.png"
                     }
                     direction="row-reverse"
                     alternComponent={
                        <Typography
                           sx={{ color: "#162c44", fontSize: { md: "18px" }, textAlign: "justify", lineHeight: 1.3 }}
                           py={{ md: 2, lg: 6 }}
                           pr={{ lg: 6 }}
                        >
                           En un entorno regulatorio cada vez más exigente, el cumplimiento de las disposiciones
                           fiscales se ha vuelto de prioridad alta, como lo es el tema del{" "}
                           <strong> Beneficiario Controlador</strong>, que se ha convertido en un factor de riesgo para
                           todas las empresas por las sanciones sumamente onerosas que pueden afectar a sus finanzas.
                        </Typography>
                     }
                     imageProps={{
                        height: "100%",
                        width: { xs: "100%", lg: "70%" },
                        alignSelf: "center",
                     }}
                  />
               </Box>
            </Box>
            <Box
               my={{ xs: -20, lg: -28 }}
               position={"relative"}
               zIndex={2}
               sx={{
                  backgroundImage: {
                     lg: `url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/curvedBg.png)`,
                  },
                  backgroundSize: "100% 130%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  bgcolor: "transparent",
                  paddingBottom: 10,
               }}
            >
               <VideoContainer
                  title={
                     <Typography
                        fontFamily={"bold"}
                        pl={{ xs: "20px", sm: "100px", md: "85px" }}
                        fontSize={{ xs: "30px", lg: "35px" }}
                        sx={{ borderBottom: 5, borderColor: "#8A95A1" }}
                     >
                        ¿Cómo funciona el{" "}
                        <Typography fontFamily={"ExtraBold"} fontSize={{ xs: "30px", lg: "35px" }} lineHeight={1.1}>
                           Módulo de Beneficiario Controlador?
                        </Typography>
                     </Typography>
                  }
                  direction="row-reverse"
                  alternComponent={
                     <Box>
                        <StyledAccordionComponent
                           title="Identificación y registro de Beneficiario Controlador"
                           content={BeneficialOwnerBodyContent} // Aquí va el contenido incluyendo el título principal
                           selectedAnswer={selectedText}
                           setSelectedAnswer={setSelectedText}
                           setSelectedVideo={setVideoContainerUrl}
                           //agregar
                           mainTitleContent={styledAccContent.details}
                           mainVideoContent={styledAccContent.videoUrl}
                        />
                     </Box>
                  }
                  videoUrl={videoContainerUrl}
                  videoPlayerProps={{ width: { lg: "80%" }, mx: "10%" }}
                  subComponent={
                     <Stack direction={"column"} spacing={2} px={{ xs: 1, md: 2 }} mx={"5%"}>
                        {selectedText}
                     </Stack>
                  }
               />
            </Box>
            <Box zIndex={1} mt={{ xs: 8, lg: -15 }}>
               <WhatIsServiceComponent
                  what={
                     <Typography sx={{ px: { xs: 0, sm: 1 } }}>
                        El Beneficiario Controlador es aquella{" "}
                        <strong>
                           {" "}
                           persona física o grupo de personas físicas que directa o indirectamente obtienen beneficios
                           de su participación en una entidad legal,
                        </strong>{" "}
                        pudiéndose tratar de una persona moral, fideicomiso u otra figura similar. Incluyendo
                        situaciones en las que estas personas, ya sea directa, indirectamente o de forma contingente,
                        ejerzan el control de la persona moral, fideicomiso o cualquier otra figura jurídica.
                        <br />
                        <br />
                        Art. 32-B Quáter Código Fiscal de la Federación
                     </Typography>
                  }
                  hasObjective
                  imageUrl={
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/beneficiarioControlador/whatIs)"
                  }
                  objective={
                     <Typography>
                        Obtener y conservar, como parte de  su contabilidad, y  proporcionar al Servicio de
                        Administración Tributaria (SAT), cuando dicha autoridad así lo  requiera, la información
                        fidedigna,  completa y actualizada, de sus  beneficiarios controladores, así como  los
                        mecanismos y procedimientos,  para conservar la información  actualizada sobre el Beneficiario
                        Controlador.
                        <br />
                        <br />
                        Art. 32-B Ter Código Fiscal de la Federación
                        <br />
                        <br />
                        Sin embargo, en Lecosy, al tener un enfoque integral en el cumplimiento normativo, las empresas
                        se encuentran obligadas también a dar avisos al Servicio de Administración Tributaria (SAT) y
                        Secretaria de Economía (SE) cualquier modificación, incorporación o adición de socios,
                        accionistas, y a su vez señalar si los sujetos ejercen control efectivo.
                     </Typography>
                  }
               />
            </Box>

            <Box textAlign="center" py={0} bgcolor="#e8ecef">
               <Box
                  textAlign="center"
                  py={5}
                  sx={{
                     backgroundImage:
                        "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/beneficiarioControlador/SECCION-6-Principles-features+1.png)",
                     backgroundSize: "fit",
                  }}
               >
                  <Typography
                     sx={{
                        color: "#162C44",
                        fontSize: { xs: "15px", md: "35px" },
                        textAlign: "center",
                        fontWeight: "bold",
                     }}
                  >
                     Conozca más
                  </Typography>
                  <IconButton
                     onClick={toggleContent}
                     sx={{
                        transform: showContent ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.3s",
                        color: "#162C44", // Cambia el color del ícono
                     }}
                  >
                     <ExpandMoreIcon sx={{ fontSize: 40, strokeWidth: 2 }} /> {/* Tamaño y grosor */}
                  </IconButton>
                  <Collapse in={showContent}>
                     <Box p={5}>
                        <Grid container spacing={3}>
                           <Grid item xs={12} md={6}>
                              <Box
                                 sx={{
                                    bgcolor: "#5D6D7C",
                                    borderBottomLeftRadius: "16px",
                                    borderBottomRightRadius: "16px",
                                    overflow: "hidden",
                                    padding: "16px", // Ajusta este valor según el espacio que prefieras
                                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Añade una sombra suave
                                 }}
                              >
                                 <Typography variant="h6" color="white" fontFamily={"bold"}>
                                    Socios o accionistas, y representantes legales
                                 </Typography>
                              </Box>
                              <Box
                                 sx={{
                                    bgcolor: "#8A95A0",
                                    borderBottomLeftRadius: "16px",
                                    borderBottomRightRadius: "16px",
                                    overflow: "hidden",
                                    padding: "16px", // Espacio alrededor del contenido
                                    borderTop: "4px solid #162C44", // Borde superior con color específico
                                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Sombra suave
                                    marginTop: "16px", // Espacio superior
                                    textAlign: "left", // Alinear texto a la izquierda
                                    paddingX: 5,
                                 }}
                              >
                                 <Typography mt={2} color="white" fontWeight="extrabold">
                                    <strong>Definición</strong>
                                 </Typography>
                                 <Typography mt={1} color="white">
                                    <strong>Socios o accionistas:</strong> Propietarios de las acciones o integrantes de
                                    una sociedad.
                                 </Typography>
                                 <Typography mt={1} color="white">
                                    <strong>Asociado:</strong> Los que tienen participaciones en asociaciones civiles.
                                 </Typography>
                                 <Typography mt={1} color="white">
                                    <strong>Representante legal:</strong> Es quien actúa en nombre de otra persona y que
                                    es reconocido por la ley.
                                 </Typography>
                              </Box>
                           </Grid>
                           <Grid item xs={12} md={6}>
                              <Box
                                 sx={{
                                    bgcolor: "#5D6D7C",
                                    borderBottomLeftRadius: "16px",
                                    borderBottomRightRadius: "16px",
                                    overflow: "hidden",
                                    padding: "16px", // Ajusta este valor según el espacio que prefieras
                                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Añade una sombra suave
                                 }}
                              >
                                 <Typography variant="h6" color="white" fontFamily={"bold"}>
                                    Control efectivo
                                 </Typography>
                              </Box>
                              <Box
                                 sx={{
                                    bgcolor: "#8A95A0",
                                    borderBottomLeftRadius: "16px",
                                    borderBottomRightRadius: "16px",
                                    overflow: "hidden",
                                    padding: "16px", // Espacio alrededor del contenido
                                    borderTop: "4px solid #162C44", // Borde superior con color específico
                                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Sombra suave
                                    marginTop: "16px", // Espacio superior
                                    textAlign: "left", // Alinear texto a la izquierda
                                    paddingX: 5,
                                 }}
                              >
                                 <Typography mt={1} color="white">
                                    La capacidad que se tiene por una persona de llevar a cabo cualquiera de los actos
                                    siguientes:
                                 </Typography>
                                 <Typography mt={1} color="white">
                                    1. Imponer decisiones en asambleas generales u órganos equivalentes.
                                 </Typography>
                                 <Typography mt={1} color="white">
                                    2. Mantener la titularidad de derechos que permitan ejercer el voto respecto de más
                                    del 50 % del capital social de una persona moral.
                                 </Typography>
                                 <Typography mt={1} color="white">
                                    3. Dirigir la administración, la estrategia o las principales políticas de una
                                    persona moral, ya sea a través de la propiedad de valores, por contrato o de
                                    cualquier otra forma.
                                 </Typography>
                              </Box>
                           </Grid>
                           <Grid item xs={12} md={6}>
                              <Box
                                 sx={{
                                    position: "relative", // Para posicionar el SVG de manera absoluta dentro de este Box
                                    bgcolor: "#46566A",
                                    borderBottomLeftRadius: "16px",
                                    borderBottomRightRadius: "16px",
                                    overflow: "visible", // Asegurar que el SVG pueda sobresalir del Box
                                    padding: "16px", // Ajusta este valor según el espacio que prefieras
                                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Añade una sombra suave
                                 }}
                              >
                                 <Typography variant="h6" color="white" fontFamily={"bold"}>
                                    Obligación
                                 </Typography>

                                 {/* SVG Positioned */}
                                 <Box
                                    component="img"
                                    src="https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/beneficiarioControlador/ICONOS+de+m%C3%B3dulos+LECOSY_Programa+de+cumplimiento.svg"
                                    alt="Programa de Cumplimiento SVG"
                                    sx={{
                                       position: "absolute",
                                       top: "60px", // Ajusta la posición para que sobresalga del Box
                                       left: "50%",
                                       transform: "translateX(-50%)", // Centrar horizontalmente
                                       width: "35px", // Ajusta el tamaño del SVG según sea necesario
                                       height: "35px",
                                       zIndex: 10, // Asegura que el SVG esté delante del Box
                                    }}
                                 />
                              </Box>

                              <Box
                                 sx={{
                                    bgcolor: "#8A95A0",
                                    borderBottomLeftRadius: "16px",
                                    borderBottomRightRadius: "16px",
                                    overflow: "hidden",
                                    padding: "16px", // Espacio alrededor del contenido
                                    borderTop: "4px solid #162C44", // Borde superior con color específico
                                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Sombra suave
                                    marginTop: "16px", // Espacio superior
                                    textAlign: "left", // Alinear texto a la izquierda
                                    paddingX: 5,
                                 }}
                              >
                                 <Typography mt={1} color="white">
                                    Presentar al Servicio de Administración Tributaria el aviso de modificación o
                                    incorporación de socios o accionistas.
                                 </Typography>

                                 <Typography mt={2} color="white" fontWeight={"bold"}>
                                    Incluyendo en el aviso:
                                 </Typography>

                                 <Box
                                    component="ul"
                                    sx={{
                                       paddingLeft: "20px",
                                       color: "white",
                                       marginTop: "8px",
                                    }}
                                 >
                                    <Typography component="li" mt={1}>
                                       Nombre y clave del RFC de los socios, accionistas, asociados y demás personas que
                                       formen parte de la estructura orgánica
                                    </Typography>
                                    <Typography component="li" mt={1}>
                                       Porcentaje de participación en el capital social
                                    </Typography>
                                    <Typography component="li" mt={1}>
                                       Objeto social de la entidad
                                    </Typography>
                                    <Typography component="li" mt={1} fontWeight={"bold"}>
                                       Señalamiento de si el sujeto ejerce el control efectivo
                                    </Typography>
                                 </Box>
                              </Box>
                           </Grid>

                           <Grid item xs={12} md={6}>
                              <Box
                                 sx={{
                                    position: "relative", // Para posicionar el SVG de manera absoluta dentro de este Box
                                    bgcolor: "#46566A",
                                    borderBottomLeftRadius: "16px",
                                    borderBottomRightRadius: "16px",
                                    overflow: "visible", // Asegurar que el SVG pueda sobresalir del Box
                                    padding: "16px", // Ajusta este valor según el espacio que prefieras
                                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Añade una sombra suave
                                 }}
                              >
                                 <Typography variant="h6" color="white" fontFamily={"bold"}>
                                    Obligación derivada
                                 </Typography>

                                 {/* SVG Positioned */}
                                 <Box
                                    component="img"
                                    src="https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/beneficiarioControlador/ICONOS+de+m%C3%B3dulos+LECOSY_Programa+de+cumplimiento.svg"
                                    alt="Programa de Cumplimiento SVG"
                                    sx={{
                                       position: "absolute",
                                       top: "60px", // Ajusta la posición para que sobresalga del Box
                                       left: "50%",
                                       transform: "translateX(-50%)", // Centrar horizontalmente
                                       width: "35px", // Ajusta el tamaño del SVG según sea necesario
                                       height: "35px",
                                       zIndex: 10, // Asegura que el SVG esté delante del Box
                                    }}
                                 />
                              </Box>

                              <Box
                                 sx={{
                                    bgcolor: "#46566A",
                                    borderBottomLeftRadius: "16px",
                                    borderBottomRightRadius: "16px",
                                    overflow: "hidden",
                                    padding: "16px", // Espacio alrededor del contenido
                                    borderTop: "4px solid #162C44", // Borde superior con color específico
                                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Sombra suave
                                    marginTop: "16px", // Espacio superior
                                    textAlign: "left", // Alinear texto a la izquierda
                                    paddingX: 5,
                                 }}
                              >
                                 <Typography mt={1} color="white">
                                    Determinar si los socios, accionistas, personas que tengan control, influencia
                                    significativa o poder de mando o representantes legales de la persona moral, sobre
                                    los que se da el aviso ejercen el control efectivo en la entidad.
                                 </Typography>
                              </Box>
                           </Grid>
                        </Grid>
                     </Box>
                     <Typography
                        sx={{
                           color: "#162C44",
                           fontSize: { xs: "13px", md: "18px" },
                           textAlign: "center",
                           fontWeight: "regular",
                           mx: { xs: 4, lg: 20 },
                        }}
                     >
                        Artículos 26 Fracción X, 27 Apartado A Fracción II, y 27 Apartado B Fracción VI del CFF; Regla
                        2.4.15 de la RMF; y Ficha de Trámite 295/CFF de la RMF.
                     </Typography>
                  </Collapse>
               </Box>
            </Box>

            <Box
               sx={{
                  backgroundImage:
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/inicio/background/Fondo-seccion-4.jpg)",
                  backgroundSize: "cover",
                  backgroundAttachment: "fixed",
                  py: { xs: 10, md: 15 },
                  px: { xs: 3, md: 10 },
                  textAlign: { xs: "center", md: "left" }, // Centrado en móvil
               }}
            >
               <Stack direction="column" spacing={3} alignItems={{ xs: "center", md: "flex-start" }}>
                  <Typography
                     sx={{
                        color: "white",
                        fontSize: { xs: "24px", md: "30px" },
                        textAlign: { xs: "center", md: "left" }, // Centrado en móvil
                        fontWeight: "700",
                     }}
                  >
                     ¿Cuáles son las sanciones?
                  </Typography>
                  <Grid container spacing={0} alignItems="center">
                     <Grid item xs={12} md={4}>
                        <Typography
                           sx={{
                              color: "white",
                              lineHeight: 1.3,
                              fontSize: { xs: "14px", md: "18px" }, // Ajustar tamaño en móviles
                              textAlign: "justify",
                              padding: { xs: "20px", md: "0px" }, // Reducir padding en móviles
                              pr: { md: "10%" },
                           }}
                        >
                           Mantener información precisa, completa y actualizada de los beneficiarios controladores no
                           solo es una obligación fiscal, sino también una práctica esencial para
                           <strong>
                              {" "}
                              garantizar la transparencia y fiabilidad de las operaciones financieras de su empresa.
                           </strong>
                        </Typography>
                     </Grid>

                     <Grid item xs={12} md={8}>
                        {/* Contenedor con scroll horizontal en pantallas pequeñas */}
                        <Box
                           sx={{
                              overflowX: { xs: "auto", md: "unset" }, // Scroll horizontal solo en móviles
                              marginTop: "16px",
                           }}
                        >
                           <Table
                              sx={{
                                 borderCollapse: "separate",
                                 borderSpacing: "16px 0px ",
                                 width: "100%",
                                 minWidth: { xs: "600px", md: "auto" }, // Asegurar que la tabla tenga un ancho mínimo en móviles
                              }}
                           >
                              <TableHead>
                                 <TableRow>
                                    <TableCell
                                       sx={{
                                          width: "60%",
                                          bgcolor: "#8A95A1",
                                          // borderBottomLeftRadius: "16px", // Ajustar borde superior izquierdo
                                          // borderBottomRightRadius: "16px", // Ajustar borde superior izquierdo
                                          padding: { xs: "8px" }, // Reducir padding en móviles
                                          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                          color: "white",
                                          fontWeight: "bold",
                                          lineHeight: "2em",
                                          fontSize: { xs: "14px", md: "18px" }, // Reducir tamaño de fuente en móviles
                                          textAlign: "center",
                                          border: "none",
                                       }}
                                    >
                                       Infracciones
                                    </TableCell>
                                    <TableCell
                                       sx={{
                                          width: "50%",
                                          bgcolor: "#8A95A1",
                                          // borderBottomLeftRadius: "16px", // Ajustar borde superior izquierdo
                                          // borderBottomRightRadius: "16px", // Ajustar borde superior izquierdo
                                          padding: { xs: "8px" },
                                          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                          color: "white",
                                          fontWeight: "bold",
                                          lineHeight: "2em",
                                          fontSize: { xs: "14px", md: "18px" },
                                          textAlign: "center",
                                          border: "none",
                                       }}
                                    >
                                       Sanciones
                                    </TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell sx={{ width: "50%", padding: 0, border: "none" }}>
                                       <Box
                                          sx={{
                                             bgcolor: "#5D6D7C",
                                             borderBottomLeftRadius: "16px", // Ajustar borde superior izquierdo
                                             borderBottomRightRadius: "16px", // Ajustar borde superior izquierdo
                                             overflow: "hidden",
                                             padding: { xs: "8px" },
                                             boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                             textAlign: "center",
                                          }}
                                       >
                                          <Typography color="white" sx={{ fontSize: { xs: "14px", md: "14px" } }}>
                                             Artículo 84-M del CFF
                                          </Typography>
                                       </Box>
                                    </TableCell>
                                    <TableCell sx={{ width: "50%", padding: 0, border: "none" }}>
                                       <Box
                                          sx={{
                                             bgcolor: "#5D6D7C",
                                             borderBottomLeftRadius: "16px", // Ajustar borde superior izquierdo
                                             borderBottomRightRadius: "16px", // Ajustar borde superior izquierdo
                                             overflow: "hidden",
                                             padding: { xs: "8px" },
                                             boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                             textAlign: "center",
                                          }}
                                       >
                                          <Typography color="white" sx={{ fontSize: { xs: "14px", md: "14px" } }}>
                                             Artículo 84-N del CFF
                                          </Typography>
                                       </Box>
                                    </TableCell>
                                 </TableRow>
                              </TableHead>

                              <TableBody>
                                 <TableRow>
                                    <TableCell
                                       sx={{
                                          width: "50%",
                                          color: "white",
                                          fontWeight: "regular",
                                          fontSize: { xs: "14px", md: "16px" },
                                          padding: "16px",
                                          border: "none",
                                          textAlign: "justify",
                                       }}
                                    >
                                       No obtener, no conservar o no presentar la información a que se refiere el
                                       artículo 32-B Ter de este Código o no presentarla a través de los medios o
                                       formatos que señale el Servicio de Administración Tributaria dentro de los plazos
                                       establecidos en las disposiciones fiscales.
                                    </TableCell>
                                    <TableCell
                                       sx={{
                                          width: "50%",
                                          color: "white",
                                          fontWeight: "bold",
                                          fontSize: { xs: "14px", md: "16px" },
                                          padding: { xs: "8px", md: "16px" },
                                          border: "none",
                                          textAlign: "center",
                                       }}
                                    >
                                       De $1,686,750.00 a $2,249,000.00 pesos.
                                    </TableCell>
                                 </TableRow>

                                 <TableRow>
                                    <TableCell
                                       sx={{
                                          width: "50%",
                                          color: "white",
                                          fontWeight: "regular",
                                          fontSize: { xs: "14px", md: "16px" },
                                          padding: "16px",
                                          border: "none",
                                          textAlign: "justify",
                                       }}
                                    >
                                       No mantener actualizada la información relativa a los beneficiarios controladores
                                       a que se refiere el artículo 32-B Ter de este Código.
                                    </TableCell>
                                    <TableCell
                                       sx={{
                                          width: "50%",
                                          color: "white",
                                          fontWeight: "bold",
                                          fontSize: { xs: "14px", md: "16px" },
                                          padding: { xs: "8px", md: "16px" },
                                          border: "none",
                                          textAlign: "center",
                                       }}
                                    >
                                       De $899,600.00 a $1,124,500.00 pesos.
                                    </TableCell>
                                 </TableRow>

                                 <TableRow>
                                    <TableCell
                                       sx={{
                                          width: "50%",
                                          color: "white",
                                          fontWeight: "regular",
                                          fontSize: { xs: "14px", md: "16px" },
                                          padding: "16px",
                                          border: "none",
                                          textAlign: "justify",
                                       }}
                                    >
                                       Presentar la información a que se refiere el artículo 32-B Ter de este Código de
                                       forma incompleta, inexacta, con errores o en forma distinta a lo señalado en las
                                       disposiciones aplicables.
                                    </TableCell>
                                    <TableCell
                                       sx={{
                                          width: "50%",
                                          color: "white",
                                          fontWeight: "bold",
                                          fontSize: { xs: "14px", md: "16px" },
                                          padding: { xs: "8px", md: "16px" },
                                          border: "none",
                                          textAlign: "center",
                                       }}
                                    >
                                       De $562,250.00 a $899,600.00 pesos.
                                    </TableCell>
                                 </TableRow>

                                 <TableRow>
                                    <TableCell sx={{ width: "50%", padding: 0, border: "none" }}>
                                       <Box
                                          sx={{
                                             bgcolor: "#5D6D7C",
                                             borderBottomLeftRadius: "16px", // Ajustar borde superior izquierdo
                                             borderBottomRightRadius: "16px", // Ajustar borde superior izquierdo
                                             overflow: "hidden",
                                             padding: { xs: "8px" },
                                             boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                             textAlign: "center",
                                          }}
                                       >
                                          <Typography
                                             color="white"
                                             sx={{ fontSize: { xs: "14px", md: "14px" } }}
                                          ></Typography>
                                       </Box>
                                    </TableCell>
                                    <TableCell sx={{ width: "50%", padding: 0, border: "none" }}>
                                       <Box
                                          sx={{
                                             bgcolor: "#5D6D7C",
                                             borderBottomLeftRadius: "16px", // Ajustar borde superior izquierdo
                                             borderBottomRightRadius: "16px", // Ajustar borde superior izquierdo
                                             overflow: "hidden",
                                             padding: { xs: "8px" },
                                             boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                             textAlign: "center",
                                          }}
                                       >
                                          <Typography
                                             color="white"
                                             sx={{ fontSize: { xs: "14px", md: "14px" } }}
                                          ></Typography>
                                       </Box>
                                    </TableCell>
                                 </TableRow>
                              </TableBody>
                           </Table>
                        </Box>
                     </Grid>
                  </Grid>
               </Stack>
            </Box>

            <Box
               sx={{
                  backgroundImage:
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/beneficiarioControlador/moduloInfoNew.png)",
                  backgroundSize: "cover",

                  py: { xs: 10, md: 15 },
                  px: { lg: 30, md: 20, xs: 3 },
               }}
            >
               <Stack direction={"column"} spacing={2}>
                  <Typography
                     sx={{
                        color: "white",
                        fontSize: { xs: "15px", md: "25px" },
                        textAlign: "center",
                     }}
                  >
                     Con nuestro módulo <strong>identifique, registre y gestione a su Beneficiario Controlador </strong>{" "}
                     de forma eficiente y en cumplimiento con las normativas,{" "}
                     <strong>evitando infracciones y simplificando su proceso de auditoría </strong>
                  </Typography>
               </Stack>
            </Box>
            <Stack
               direction={"column"}
               spacing={3}
               p={{ xs: 3, lg: 10 }}
               sx={{
                  backgroundImage:
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/Fondo+beneficios.png)",
                  backgroundPosition: "center top",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
               }}
            >
               <Box display={"flex"} justifyContent={"center"}>
                  <Typography
                     textAlign={"center"}
                     color={"#162c44"}
                     fontFamily={"bold"}
                     fontSize={{ xs: "1.6rem", lg: "2rem" }}
                     sx={{ borderBottom: 5, lineHeight: 1.4, pt: 6, mx: 1 }}
                  >
                     3 Beneficios que logrará con la <br></br> implementación de nuestro Módulo Beneficiario Controlador
                  </Typography>
               </Box>
               <Grid container>
                  {BeneficialOwnerBenefits.map((benefit, i) => {
                     return (
                        <Grid
                           item
                           md={12}
                           sx={{
                              display: "flex",
                              lignItems: "center",
                              justifyContent: "center",
                              alingSelf: "center",
                           }}
                        >
                           <Box
                              columnGap={{ sm: 0, md: 4, lg: 0 }}
                              id={`section${i + 1}`}
                              key={benefit.title}
                              sx={{
                                 display: "flex",
                                 flexDirection: {
                                    xs: "column",
                                    md: i % 2 === 0 ? "row" : "row-reverse",
                                 },
                                 alignItems: { md: "center" },
                                 justifyContent: "center",
                                 alingSelf: "center",

                                 textAlign: "justify",
                                 py: { xs: 4, sm: 2, md: 0 },
                                 px: { xs: 1, sm: 4 },
                                 maxWidth: { md: "1000px" },
                                 minWidth: { lg: "1240px" },
                              }}
                           >
                              {descriptionZone(benefit, i)}
                              {imageZone(benefit, i % 2 === 0)}
                           </Box>
                        </Grid>
                     );
                  })}
               </Grid>
               <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  sx={{ pr: { xs: "30px", sm: "54px", md: "65px", lg: "58px" } }}
               >
                  <ContactConsultantButton />
               </Box>
            </Stack>
            <Box className="gradientBox902" sx={{ px: { xs: 2, md: 15 }, py: { xs: 3, md: 5 } }} id={"plans"}>
               <Typography
                  color={"white"}
                  textAlign={"center"}
                  sx={{
                     pt: 8,
                     px: { xs: "8%", sm: "0%" },
                     fontSize: { xs: "1.6rem", md: "36px" },
                     lineHeight: { xs: "1.3", md: "1.5" },
                  }}
               >
                  <strong>
                     Modalidades de identificación y gestión<br></br> de Beneficiario Controlador
                  </strong>
               </Typography>

               <Box
                  sx={{
                     display: "flex",
                     justifyContent: { xs: "center", lg: "space-evenly" },
                     flexDirection: { xs: "column", md: "row" },
                     py: { xs: 6, md: 9 }, // Ajuste dinámico del padding vertical
                  }}
               >
                  <Box display={"grid"} sx={{ justifyContent: "center", m: { xs: "0px", md: "0px 10px" } }}>
                     {/* Box superior con texto justificado */}

                     <Box
                        sx={{
                           p: { xs: "40px", md: "35px 35px" }, // Padding más pequeño en pantallas pequeñas
                           maxWidth: { xs: "100%", md: "500px" }, // Max-width adaptativo
                        }}
                     >
                        <Typography textAlign={"justify"} sx={{ py: 1, color: "#FFFFFF" }}>
                           No importa si eres un despacho que presta servicios profesionales a empresas, o un grupo
                           empresarial: nuestro sistema está diseñado para gestionar de manera integral y eficiente a
                           los beneficiarios controladores bajo un enfoque multiempresa.
                           <strong>
                              {" "}
                              Así, minimiza el riesgo de sanciones y garantiza el cumplimiento normativo de forma
                              objetiva y segura.
                           </strong>
                        </Typography>
                     </Box>
                     {/* Box inferior con texto justificado */}
                     <Box
                        sx={{
                           p: { xs: "40px", md: " 35px" }, // Ajuste de padding para pantallas pequeñas
                           maxWidth: { xs: "100%", md: "500px" }, // Max-width adaptativo
                           bgcolor: "#D9D9D9",
                           borderRadius: 2,
                           alignContent: "center",
                        }}
                     >
                        <Typography textAlign={"justify"} sx={{ pb: 1, color: "#162C44" }}>
                           Para el uso del sistema se requiere un <strong>proceso de implementación</strong> (pago
                           único), del cual tiene una duración 4 horas, con un costo de{" "}
                           <strong>$2,950.00 pesos más IVA</strong>, en el cual se hace una concientización de la
                           gestión del Beneficiario Controlador, y explicación del uso del sistema.
                        </Typography>
                        <Typography textAlign={"justify"} sx={{ py: 1, color: "#162C44" }}>
                           Si se agregan más empresas, el tabulador sigue la misma lógica mencionada anteriormente: por
                           cada 5 empresas adicionales, se suman $100 pesos.
                        </Typography>
                     </Box>
                  </Box>

                  <Box display={"grid"} sx={{ justifyContent: "center", pt: { xs: 5, md: 0 } }}>
                     <Box
                        // gridRow={"5/10"}
                        // gridColumn={"1/4"}
                        sx={{
                           bgcolor: "#D9D9D9",
                           p: { xs: "20px", md: "40px 15px" }, // Ajuste de padding para pantallas pequeñas
                           borderRadius: 2,
                           display: "flex",
                           flexDirection: "column",
                           justifyContent: "center",
                           maxWidth: { xs: "100%", md: "500px" }, // Max-width adaptativo
                           minHeight: { xs: "500px", md: "580px" }, // Ajuste dinámico de altura mínima
                           maxHeight: { xs: "860px", md: "700px" },
                        }}
                     >
                        <Typography
                           textAlign={"center"}
                           color={"#162c44"}
                           fontFamily={"bold"}
                           sx={{ fontSize: { xs: "28px", md: "40px" }, mt: "5%", lineHeight: "1" }} // Ajuste de font-size dinámico
                        >
                           Inversión por número de empresas
                        </Typography>

                        <Box
                           sx={{
                              mt: 4,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                           }}
                        >
                           {/* Tabla de contenido */}
                           <Box
                              component="table"
                              sx={{
                                 width: "100%",
                                 maxWidth: "400px",
                                 borderSpacing: "0",
                                 borderCollapse: "collapse",
                                 textAlign: "left",
                              }}
                           >
                              <Box component="thead" sx={{ borderBottom: "2px solid #162C44" }}>
                                 <Box component="tr">
                                    <Box
                                       component="th"
                                       sx={{
                                          paddingBottom: "10px",
                                          color: "#162C44",
                                          fontSize: "18px",
                                          textAlign: "center",
                                          borderBottom: "4px solid #162C44",
                                          borderRight: "2px solid #8A95A1",
                                          lineHeight: "1",
                                       }}
                                    >
                                       Licencia por rango de empresas
                                    </Box>
                                    <Box
                                       component="th"
                                       sx={{
                                          paddingBottom: "10px",
                                          color: "#162C44",
                                          fontSize: "18px",
                                          textAlign: "center",
                                          borderBottom: "4px solid #162C44",
                                          lineHeight: "1",
                                       }}
                                    >
                                       Costo mensual
                                    </Box>
                                 </Box>
                              </Box>

                              <Box component="tbody">
                                 {[
                                    { range: "1 a 5 empresas", cost: "$550.00" },
                                    { range: "6 a 10 empresas", cost: "$700.00" },
                                    { range: "11 a 15 empresas", cost: "$800.00" },
                                    { range: "16 a 20 empresas", cost: "$900.00" },
                                 ].map((item, index) => (
                                    <Box component="tr" key={index} sx={{ borderBottom: "none" }}>
                                       <Box
                                          component="td"
                                          sx={{
                                             padding: "8px",
                                             color: "#162C44",
                                             textAlign: "center",
                                             borderRight: "2px solid #8A95A1",
                                          }}
                                       >
                                          <Box
                                             sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                width: "100%",
                                             }}
                                          >
                                             <Box
                                                sx={{
                                                   width: "24px",
                                                   height: "24px",
                                                   borderRadius: "50%",
                                                   display: "flex",
                                                   justifyContent: "flex-start", // Alinea el icono a la izquierda
                                                   alignItems: "center",
                                                   marginRight: 4,
                                                }}
                                             >
                                                <CheckCircleIcon
                                                   sx={{
                                                      color: "#8A95A1",
                                                      fontSize: "28px",
                                                      fontWeight: "bold",
                                                   }}
                                                />
                                             </Box>
                                             <Typography
                                                sx={{
                                                   fontWeight: "bold",
                                                   marginRight: "auto",
                                                }}
                                             >
                                                {item.range}
                                             </Typography>
                                          </Box>
                                       </Box>

                                       <Box
                                          component="td"
                                          sx={{
                                             padding: "8px",
                                             color: "#162C44",
                                             textAlign: "center",
                                          }}
                                       >
                                          <Typography sx={{ fontWeight: "bold" }}>{item.cost}</Typography>
                                       </Box>
                                    </Box>
                                 ))}
                              </Box>
                           </Box>
                           <Button
                              onClick={() => navigate("/contacto")}
                              sx={{
                                 borderRadius: 2,
                                 px: 2,
                                 alignSelf: "center",
                                 my: 2,
                                 mt: 4,
                                 bgcolor: "#162c44",
                                 "&:hover": { bgcolor: "#5C6C7C" },
                              }}
                           >
                              INVIERTA YA
                           </Button>
                           <Typography sx={{ paddingTop: 5, fontSize: 10 }}>*Los precios no incluyen IVA.</Typography>
                        </Box>
                     </Box>
                  </Box>
               </Box>
            </Box>

            <Box
               sx={{
                  backgroundImage:
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/Figures/Figura-5.png)",
                  backgroundSize: { xs: "0% 0%", md: "55% 100%" },
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "bottom right",
               }}
            >
               <Box
                  height={{ lg: "600px" }}
                  width={"400px"}
                  position={"absolute"}
                  right={0}
                  my={"2%"}
                  sx={{
                     backgroundImage: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/Capa_1.png)",
                     backgroundSize: "cover",
                     backgroundRepeat: "no-repeat",
                     backgroundPosition: "center",
                  }}
               />
               <Typography
                  fontFamily={"bold"}
                  color={"primary"}
                  sx={{
                     px: { xs: 2, md: 10 },
                     pt: 5,
                     fontSize: { xs: "30px", md: "40px" },
                  }}
               >
                  Preguntas frecuentes
               </Typography>
               {QuestionAccordion(BeneficialOwnerQuestions, true)}
            </Box>
            {ScrollToTopButton(window)}
            <Footer />
         </ScrollToTop>
      </ThemeConfig>
   );
};
