import { Box, Divider, Stack } from "@mui/material";
import { VisibleElement } from "../lib/hooks/VisibleElement";
import React, { useRef, useState } from "react";
import ReactPlayer from "react-player/lib";
import PropTypes from "prop-types";
import { AnimationOnScroll } from "react-animation-on-scroll";

const VideoContainer = ({
   videoUrl,
   direction = "row",
   title,
   subTitle,
   subComponent,
   alternComponent,
   videoPlayerProps,
}) => {
   const videoIsVisible = useRef(null);
   const [isPlaying, setIsPlaying] = useState(false);
   const portview = videoIsVisible !== null ? VisibleElement(videoIsVisible, `-180px`) : false;
   return (
      <Stack direction={"column"} spacing={2} py={5}>
         {title ? (
            <div>
               <Box
                  color={"#46566A"}
                  display={"flex"}
                  minWidth={{ lg: "45%" }}
                  maxWidth={{ lg: "70%" }}
                  justifyContent={"left"}
               >
                  {title}
               </Box>
            </div>
         ) : undefined}
         <Stack direction={{ xs: direction === "row" ? "column" : "column-reverse", md: direction }} spacing={5} py={5}>
            <Stack direction={"column"} alignItems={"center"} spacing={1} flex={1}>
               {subTitle}
               <Box mt={-1} ref={videoIsVisible} {...videoPlayerProps}>
                  <ReactPlayer
                     playing={isPlaying && portview}
                     // muted
                     onReady={() => setIsPlaying(true)}
                     url={videoUrl}
                     loop
                     height={"100%"}
                     width={"100%"}
                  />
                  <Box overflow={"hidden"}>
                     <AnimationOnScroll
                        animateIn="animate__slideInLeft"
                        animateOnce
                        animatePreScroll={false}
                        duration={0.75}
                        initiallyVisible
                     >
                        <Divider sx={{ borderBottomWidth: 3, bgcolor: "#8A95A1", mt: 0.5, width: "100%" }} />
                     </AnimationOnScroll>
                  </Box>
               </Box>
               <Box py={2} width={"90%"} minHeight={"150px"}>
                  {subComponent}
               </Box>
            </Stack>
            <Box flex={1}>{alternComponent}</Box>
         </Stack>
      </Stack>
   );
};

VideoContainer.propTypes = {
   videoUrl: PropTypes.string.isRequired,
   direction: PropTypes.string.isRequired,
   title: React.Component,
   subTitle: React.Component || PropTypes.string,
   subComponent: React.Component,
   alternComponent: React.Component,
   videoPlayerProps: PropTypes.object,
};

export default VideoContainer;
