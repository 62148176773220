import { Box, Divider, Stack, Typography, Grid } from "@mui/material";
import { VisibleElement } from "../lib/hooks/VisibleElement";
import React, { useRef, useState, useEffect } from "react";

import PropTypes from "prop-types";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { isVisible } from "@testing-library/user-event/dist/utils";

const ImageAccContainer = ({
   subTitleR,
   imageUrl,
   direction = "row",
   title,

   subComponent,
   alternComponent,
}) => {
   const [isVisible, setIsVisible] = useState(false);
   const gridRef = useRef(null); // Referencia para cuando aparesca en pantalla

   useEffect(() => {
      // Configura el IntersectionObserver
      const observer = new IntersectionObserver(
         ([entry]) => {
            if (entry.isIntersecting) {
               setIsVisible(false);
               // Cuando la imagen cambia, primero "desaparece"
               // Después de un pequeño retraso, activamos la animación de nuevo
               setTimeout(() => {
                  setIsVisible(true); // Vuelve a poner la imagen visible con animación
               }, 300); // Retraso para permitir que la animación se vea
               observer.unobserve(gridRef.current); // Deja de observar después de la primera vez que es visible
            }
         },
         { threshold: 0.5 } // El 50% del Grid debe estar visible para activar la animación
      );

      if (gridRef.current) {
         observer.observe(gridRef.current);
      }

      return () => {
         if (gridRef.current) {
            observer.unobserve(gridRef.current);
         }
      };
   }, [imageUrl]); // Se ejecuta cada vez que imageUrl cambia

   const imageIsVisible = useRef(null);
   const portview = imageIsVisible !== null ? VisibleElement(imageIsVisible, `-180px`) : false;
   return (
      <Stack direction={"column"} spacing={2} py={5}>
         {title ? (
            <div>
               <Box
                  color={"#46566A"}
                  display={"flex"}
                  minWidth={{ lg: "45%" }}
                  maxWidth={{ lg: "70%" }}
                  justifyContent={"left"}
               >
                  {title}
               </Box>
            </div>
         ) : undefined}
         <Grid
            container
            direction={{ xs: direction === "row" ? "column" : "column-reverse", md: direction }}
            spacing={{ xs: 0, sm: 5, md: 0, lg: 5 }}
            py={{ xs: 4, sm: 0, md: 5 }}
         >
            <Grid item md={9} lg={9} direction={"column"} alignItems={"center"} spacing={1} flex={1}>
               <Grid
                  container
                  ref={imageIsVisible}
                  className={isVisible ? "visible" : "hidden"}
                  sx={{
                     display: "flex",
                     justifyContent: { xs: "center" },
                     alignItems: "center",
                     mt: { md: -3, lg: -5 },

                     opacity: isVisible ? 1 : 0,
                     transition: "opacity 0s ease-out",
                  }}
               >
                  <Grid item sm={4} md={6} lg={5} height={"100%"} width={"auto"}>
                     <Box
                        ref={gridRef}
                        component="img"
                        src={imageUrl}
                        sx={{
                           width: "100%",
                           maxWidth: "500px",
                           maxHeight: { xs: "260px", sm: "400px", md: "100%" },
                           mt: { xs: 6, sm: 0 },
                           opacity: isVisible ? 1 : 0,
                           transform: isVisible ? "translateY(0)" : "translateY(-40px)",
                           transition: "opacity 1s ease-out, transform 1s ease-out",
                        }}
                     ></Box>
                  </Grid>

                  <Grid
                     item
                     sm={8}
                     md={6}
                     lg={7}
                     overflow={"hidden"}
                     sx={{
                        opacity: isVisible ? 1 : 0,
                        transform: isVisible ? "translateX(0)" : "translateX(100%)",
                        transition: "opacity 1s ease-out, transform 1s ease-out",
                     }}
                  >
                     <AnimationOnScroll
                        animateIn="animate__slideInRight"
                        animateOnce
                        animatePreScroll={false}
                        duration={0.75}
                        initiallyVisible
                     >
                        <Typography
                           fontFamily={"ExtraBold"}
                           fontSize={{ xs: "18.5px", sm: "20px" }}
                           color={"#162c44"}
                           textAlign={"left"}
                           sx={{ mx: { xs: 3 }, pt: { xs: 5, md: 0, lg: 5 } }}
                        >
                           {subTitleR}
                        </Typography>
                        <Divider
                           sx={{
                              mx: { xs: 3 },
                              bgcolor: "#162c44",
                              width: { xs: "99%", lg: "99%" },
                              borderBottomWidth: "3px",
                           }}
                        />
                        <Box
                           py={2}
                           width={{ sm: "80%" }}
                           minHeight={"150px"}
                           sx={{ mx: { xs: 3 }, maxWidth: { lg: "500px" } }}
                        >
                           {subComponent}
                        </Box>
                     </AnimationOnScroll>
                  </Grid>
               </Grid>
            </Grid>
            <Grid item md={3} lg={3} flex={1}>
               {alternComponent}
            </Grid>
         </Grid>
      </Stack>
   );
};

ImageAccContainer.propTypes = {
   imageUrl: PropTypes.string.isRequired,
   subTitleR: PropTypes.string.isRequired,
   direction: PropTypes.string.isRequired,
   title: React.Component,
   subTitle: React.Component || PropTypes.string,
   subComponent: React.Component,
   alternComponent: React.Component,
};

export default ImageAccContainer;
