import { Box, Stack, Typography } from "@mui/material";
import ReactPlayer from "react-player";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { VisibleElement } from "../../lib/hooks/VisibleElement";

const AdditionalSteps = ({ index, title, videoUrl, description }) => {
   const videoIsVisible = useRef(null);
   const [isPlaying, setIsPlaying] = useState(false);
   const portview = videoIsVisible !== null ? VisibleElement(videoIsVisible, `-180px`) : false;
   return (
      <Stack direction={"column"} spacing={2} color={"#8A95A0"}>
         <Stack direction={"row"} spacing={0.8} minHeight={"95px"}>
            <Typography alignSelf={"center"} fontFamily={"ExtraBold"} fontSize={"25px"} color={"#162c44"}>
               {index}
            </Typography>
            <Box bgcolor={"#162c44"} width={"6px"} height={"40px"} alignSelf={"center"} />
            <Typography fontFamily={"bold"} fontSize={"19px"} alignSelf={"center"} lineHeight={1.25}>
               {title}
            </Typography>
         </Stack>
         <Box ref={videoIsVisible} sx={{ width: "100%", borderBottom: 4, borderColor: "#8A95A1" }}>
            <ReactPlayer
               url={videoUrl}
               playing={isPlaying && portview}
               onReady={() => setIsPlaying(true)}
               muted
               loop
               height={"170px"}
               width={"100%"}
            />
         </Box>
         <Typography textAlign={"justify"}>{description}</Typography>
      </Stack>
   );
};

AdditionalSteps.propTypes = {
   index: PropTypes.number.isRequired,
   title: PropTypes.string.isRequired,
   videoUrl: PropTypes.string.isRequired,
   description: PropTypes.string.isRequired,
};

export default AdditionalSteps;
