import { Box, Divider, Slide, Stack, Typography } from "@mui/material";
import ContactConsultantButton from "./contactConsultantButton";
import propTypes from "prop-types";

const ServiceTitleSectionIconFirst = ({ title, phrase, icon, zIndex = 1 }) => {
   return (
      <Stack
         direction={"column"}
         spacing={{ xs: 3, lg: 10 }}
         sx={{ pt: { xs: 5, md: 30, lg: 29 }, pb: { xs: 5, md: 10 }, px: "5%" }}
         zIndex={zIndex}
      >
         <Stack direction={"row"} spacing={1} pl={{ lg: 1 }} overflow={"hidden"} sx={{ pt: { xs: 5, md: 0 } }}>
            <Box
               width={{ xs: "160px", md: "140px", lg: "120px" }}
               height={{ xs: "100px", md: "140px", lg: "120px" }}
               sx={{
                  backgroundImage: icon,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 100%",
                  backgroundPosition: "center center",
                  ml: { xs: -1, sm: -4, md: 0 },
               }}
            />
            <Slide direction="top" in={true} appear timeout={500}>
               <Stack direction={"row"} spacing={1}>
                  {/* <Box width={{ xs: "0", lg: "10px" }} height={"120px"} bgcolor={"#162c44"} /> */}
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                     <Typography
                        fontFamily={"bold"}
                        color={"primary"}
                        sx={{ fontSize: { xs: "35px", md: "50px" }, lineHeight: 1, pb: 1 }}
                     >
                        {title}
                     </Typography>
                     <Divider sx={{ bgcolor: "#8A95A1", borderBottomWidth: "5px" }} />
                     <Typography
                        color={"primary"}
                        sx={{
                           fontSize: { xs: "20px", md: "20px" },
                           lineHeight: 1.2,
                           py: 1,
                           maxWidth: { xs: "300px", sm: "240px", md: "470px" },
                        }}
                     >
                        {phrase}
                     </Typography>
                  </Box>
               </Stack>
            </Slide>
         </Stack>
         <Box pl={{ lg: 1 }}>
            <ContactConsultantButton />
         </Box>
      </Stack>
   );
};

ServiceTitleSectionIconFirst.propTypes = {
   title: propTypes.string.isRequired,
   phrase: propTypes.string.isRequired,
   icon: propTypes.string.isRequired,
   zIndex: propTypes.number.isRequired,
};

export default ServiceTitleSectionIconFirst;
