import { Box, Button, Divider, Icon, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export const StyledAccordion = ({
   question,
   answer,
   selectedAnswer,
   //selectedSubTitleR,
   iconUrl,
   setSelectedAnswer,
   setSelectedImage,
   imageUrl,
   subTitleR,
   setSelectedSubTitleR,
}) => {
   const handleClick = (event) => {
      setSelectedImage(imageUrl);
      setSelectedSubTitleR(subTitleR);
      setSelectedAnswer(answer);
      event.preventDefault();
   };

   return (
      <Stack spacing={0.2}>
         <Button
            className={selectedAnswer === answer ? "gradientBox904" : undefined}
            onClick={handleClick}
            onTouchStart={handleClick}
            disableGutters
            style={{
               backgroundColor: "#E8ECEF",
               padding: "8px 0",
               borderTopLeftRadius: 0,
               borderBottomLeftRadius: 0,
               borderTopRightRadius: 5,
               borderBottomRightRadius: 5,
               width: "100%",
               textTransform: "none",
            }}
         >
            <Stack spacing={4} direction={"row"} width={"100%"} justifyContent={"space-between"} pl={1}>
               <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                     component="img"
                     src={iconUrl}
                     alt="Icono"
                     sx={{
                        alignSelf: "center",
                        width: 30,
                        height: 30,
                        ml: 3,
                     }}
                  />
                  <Typography
                     fontFamily={"ExtraBold"}
                     fontSize={selectedAnswer === answer ? "18px" : "15px"}
                     color={"#162c44"}
                     textAlign={"left"}
                     sx={{ ml: 0.5 }}
                  >
                     {question}
                  </Typography>
               </Box>

               <ChevronRightIcon
                  sx={{
                     alignSelf: "center",
                     ml: "0!important",
                     display: selectedAnswer === answer ? "none" : "block",
                     color: "#162c44",
                  }}
               />
            </Stack>
         </Button>

         <Divider
            sx={{
               bgcolor: "#162c44",
               width: { xs: "97%", lg: "95%" },
               borderBottomWidth: "3px",

               display: selectedAnswer === answer ? "block" : "none",
            }}
         />
      </Stack>
   );
};

export const StyledAccordionComponentImage = ({
   title,

   mainTitleContent,
   mainImageContent,
   mainSubTitleRContent,
   content,

   selectedAnswer,
   setSelectedAnswer,
   setSelectedImage,
   setSelectedSubTitleR,
}) => {
   const handleClick = () => {
      setSelectedImage(mainImageContent);
      setSelectedSubTitleR(mainSubTitleRContent);
      setSelectedAnswer(mainTitleContent);
   };

   return (
      <Stack
         direction={"column"}
         maxWidth={{ lg: "100%" }}
         sx={{ ml: { md: 0, lg: 5 }, maxWidth: { xs: "99%", sm: "90%", md: "250px" } }}
      >
         <Button
            onClick={() => {
               handleClick();
            }}
            onTouchStart={() => {
               handleClick();
            }}
            disableGutters
            className={selectedAnswer.key === "mainTitleContent" ? "gradientBox904" : undefined}
            sx={{
               borderTopRightRadius: 8,
               borderBottomRightRadius: 8,
               "&:hover": { cursor: "pointer" },
               pr: "0!important",
               pl: "25px!important",
            }}
            display={"flex"}
            bgcolor={"#E8ECEF"}
            style={{
               backgroundColor: "#E8ECEF",

               borderTopLeftRadius: 0,
               borderBottomLeftRadius: 0,
               borderTopRightRadius: 5,
               borderBottomRightRadius: 5,
               width: "100%",
               textTransform: "none",
               justifyContent: "space-between",
            }}
         >
            {/* animacion */}

            <Box sx={{ display: "flex", alignItems: "center" }} pr={1}>
               <Box
                  component="img"
                  src="https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/GestionModulos.svg"
                  alt="Icono"
                  sx={{
                     alignSelf: "center",
                     width: 30,
                     height: 30,
                     ml: 1,
                  }}
               />
               <Typography
                  fontFamily={"ExtraBold"}
                  py={1}
                  fontSize={selectedAnswer.key === "mainTitleContent" ? "18px" : "16px"}
                  color={"#162c44"}
                  sx={{ ml: 1 }}
                  textAlign={"left"}
               >
                  {title}
               </Typography>
            </Box>
            <ChevronRightIcon
               sx={{
                  alignSelf: "center",
                  display: selectedAnswer.key === "mainTitleContent" ? "none" : "block",
                  color: "#162c44",
               }}
            />
         </Button>
         <Divider
            sx={{
               bgcolor: "#162c44",
               width: { xs: "97%", lg: "95%" },
               borderBottomWidth: "3px",
               display: selectedAnswer.key === "mainTitleContent" ? "block" : "none",
            }}
         />
         <Stack direction={"column"} py={0.75} spacing={0.75}>
            {content.map((e) => {
               return (
                  <StyledAccordion
                     key={e.question}
                     {...e}
                     selectedAnswer={selectedAnswer}
                     setSelectedAnswer={setSelectedAnswer}
                     setSelectedSubTitleR={setSelectedSubTitleR}
                     setSelectedImage={setSelectedImage}
                  />
               );
            })}
         </Stack>
      </Stack>
   );
};

StyledAccordionComponentImage.propTypes = {
   title: PropTypes.string.isRequired,

   content: PropTypes.object.isRequired,
   selectedAnswer: PropTypes.string,

   setSelectedAnswer: PropTypes.func,
   setSelectedSubTitleR: PropTypes.func,
   setSelectedImage: PropTypes.func,
   mainTitleContent: PropTypes.string,
   mainImageContent: PropTypes.string,
   mainSubTitleRContent: PropTypes.string,
};
StyledAccordion.propTypes = {
   question: PropTypes.string.isRequired,
   iconUrl: PropTypes.string.isRequired,
   answer: PropTypes.string.isRequired,
   selectedAnswer: PropTypes.string,
   setSelectedAnswer: PropTypes.func,
   setSelectedImage: PropTypes.func,
   imageUrl: PropTypes.string,
   setSelectedSubTitleR: PropTypes.func,
   subTitleR: PropTypes.string.isRequired,
};
