import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";

const WhatIsServiceComponentRepse = ({ what, objective, hasObjective = false, imageUrl, fullHeight = false }) => {
   return (
      <Stack
         direction={{ xs: "column-reverse", lg: hasObjective ? "row-reverse" : "row" }}
         sx={{ bgcolor: "#46566A", display: "flex", minHeight: "600px" }}
      >
         <Stack direction={"column"} flex={1}>
            {hasObjective ? (
               <Box
                  flex={1}
                  pb={10}
                  pt={10}
                  px={{ xs: 2, sm: 5, lg: 10 }}
                  bgcolor={"#748190"}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
               >
                  <Typography
                     fontFamily={"bold"}
                     color={"white"}
                     lineHeight={1} // Ajusta el interlineado aquí
                     sx={{
                        mb: 2,
                        px: { xs: 2, md: 15, lg: 1 },
                        fontSize: { xs: "28px", md: "36px" },
                        letterSpacing: { lg: 0.01 },
                        lineHeight: 1.2,
                     }}
                  >
                     ¿Por qué es importante cumplir con el REPSE?
                  </Typography>
                  <Typography color={"white"} textAlign={"justify"} sx={{ px: { xs: 2, sm: 4, md: 15, lg: 1 } }}>
                     {objective}
                  </Typography>
                  <Box
                     width={{ xs: "100%", md: "100%", lg: "20%" }}
                     height={{ xs: "800px", sm: "670px", md: "620px", lg: "900px" }}
                     position={"absolute"}
                     right={{ xs: "0%", lg: "0%" }}
                     sx={{
                        backgroundImage:
                           "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/gobierno-corporativo/fondos/Fondo-Seccion-3-derecho.png)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: { xs: "300px 300px", lg: "350px 350px" },
                        backgroundPosition: "bottom right",
                        opacity: 0.15,
                     }}
                  ></Box>
               </Box>
            ) : undefined}
         </Stack>
         <Box flex={1}>
            <Box height={"100%"} px={0} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
               {!hasObjective ? undefined : (
                  <Box
                     width={{ xs: "100%", lg: "20%" }}
                     height={{ xs: "600px", md: "400px", lg: "675px" }}
                     position={"absolute"}
                     right={{ xs: "0", lg: "50%" }}
                  />
               )}
               <Box
                  sx={{
                     width: "100%",
                     height: fullHeight ? "100%" : { xs: "350px", lg: "800px" },
                     backgroundImage: imageUrl,
                     backgroundSize: "cover",
                     backgroundRepeat: "no-repeat",
                     backgroundPosition: { xs: "center center", md: "center center", lg: "top center" },
                  }}
               />

               <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  sx={{ px: { xs: 4, sm: 8, md: 16, lg: 10 }, py: { xs: "10px", sm: "20px", md: "50px" } }}
               >
                  {/* <Typography
                     color={"white"}
                     textAlign={"justify"}
                     sx={{ fontStyle: "italic", mt: { lg: 2 }, mb: { lg: 7 } }}
                  >
                     {what}
                  </Typography> */}
               </Box>
            </Box>
         </Box>
      </Stack>
   );
};

WhatIsServiceComponentRepse.propTypes = {
   what: PropTypes.string.isRequired,
   why: PropTypes.string,
   objective: PropTypes.string,
   hasObjective: PropTypes.bool,
   imageUrl: PropTypes.string,
   fullHeight: PropTypes.bool,
};

export default WhatIsServiceComponentRepse;
