import { useState, useEffect, useRef } from "react";
import { NavBar, WriteUs, Footer } from "../components";
import { Box, Typography, Grid, Button, Stack, Fade, Slide, Container } from "@mui/material";
import { ThemeConfig } from "../config/theme.config";
import ScrollToTop from "../wrapper/scrollToTop";
import "./gradientBoxes.css";
import "./boxTransitions.css";
import { QuestionAccordion } from "../components/questionAccordion";
import { BenefitCards } from "../components/benefitCards";
import { ScrollToTopButton } from "../components/scrollToTopButton";
import "./mouseTracker.css";
import { ComplaintPlatformButton } from "../components/ComplaintPlatformButton";
import {
   getWordpressBlog,
   getImageUrl,
   getCroppedDescription,
   replaceCharacterWithSpace,
} from "../lib/wordpressClient";
import ExternBlogSection from "../components/externBlogSection";
import VideoContainerHome from "../components/VideoContainerHome";
import ModuleCard from "../components/moduleCards";
import { modulesInfo, modulesInfoTwo } from "../const/modulesCardContent.js";
import { homeQuestions } from "../const/AccordionQuestions.js";
import ContactFloatingButton from "../components/contactFloatingButton.jsx";
import ContactConsultantButton from "../components/contactConsultantButton.jsx";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Slider from "react-slick";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Configuración del carousel
const carouselSettings = {
   dots: false, // Elimina los puntos
   infinite: true,
   speed: 500,
   slidesToShow: 4,
   responsive: [
      {
         breakpoint: 1200, // Breakpoint para pantallas grandes (lg)
         settings: {
            slidesToShow: 2,
         },
      },
      {
         breakpoint: 992, // Breakpoint para pantallas medianas (md)
         settings: {
            slidesToShow: 2,
         },
      },
      {
         breakpoint: 600, // Breakpoint para pantallas pequeñas (sm)
         settings: {
            slidesToShow: 1,
         },
      },
      {
         breakpoint: 0, // Breakpoint para pantallas extra pequeñas (xs)
         settings: {
            slidesToShow: 1,
         },
      },
   ],
   slidesToScroll: 1,
   arrows: true, // Habilitar las flechas
   autoplay: true, // Habilitar el cambio automático
   autoplaySpeed: 3000, // Intervalo entre cambios automáticos
   centerMode: true, // Habilitar el modo centrado
   centerPadding: "20", // Elimina el padding extra en los elementos centrados
   nextArrow: (
      <ExpandMoreIcon
         sx={{
            width: 60,
            height: 60,
            fill: "#162C44",
            position: "absolute",
            right: -45,
            top: "46%",
            transform: "translateY(-50%)",
            zIndex: 1,
            transform: "rotate(-90deg)",
            "&:hover": { color: "#5D6D7C" }, // Cambia el color a gris al hacer hover
         }}
      />
   ),
   prevArrow: (
      <ExpandMoreIcon
         sx={{
            width: 60,
            height: 60,
            fill: "#162C44",
            position: "absolute",
            left: -45,
            top: "46%",
            transform: "translateY(-50%)",
            zIndex: 1,
            transform: "rotate(90deg)",
            "&:hover": { color: "#5D6D7C" }, // Cambia el color a gris al hacer hover
         }}
      />
   ),
};
const sliderSettings = {
   autoplay: true,
   speed: 1000,
   autoplaySpeed: 5000,
   infinite: true,
   slidesToShow: 1,
   slidesToScroll: 1,
   arrows: false,
};
export const HomeScreen = () => {
   const [wpData, setWpData] = useState([]);
   const bannerRef = useRef(null);

   const iframeRef = useRef(null);

   useEffect(() => {
      // Aquí va la lógica de tu otro useEffect si la tienes
      const wordpress = async () => {
         const blogsList = await getWordpressBlog();
         setWpData(blogsList);
      };
      wordpress();

      // Configura el IntersectionObserver para controlar la reproducción del video
      const observer = new IntersectionObserver(
         ([entry]) => {
            if (entry.isIntersecting) {
               // Reproduce el video cuando está en la vista
               iframeRef.current.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', "*");
            } else {
               // Pausa el video cuando sale de la vista
               iframeRef.current.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', "*");
            }
         },
         { threshold: 0.5 } // El umbral determina cuándo activar la reproducción (50% visible)
      );

      if (iframeRef.current) {
         observer.observe(iframeRef.current);
      }
      return () => {
         if (iframeRef.current) {
            observer.unobserve(iframeRef.current);
         }
      };
   }, []); // Asegúrate de incluir dependencias específicas si tu otro useEffect las requiere

   return (
      <ThemeConfig>
         <ScrollToTop>
            <Box
               height={"100vh"}
               py={10}
               sx={{
                  // backgroundImage:
                  //    "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/inicio/background/Banner.png)",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: { xs: "100%", md: "100%" },
               }}
            >
               <Box position={"relative"} top={0} width={"100%"} zIndex={-1} sx={{ mt: "-80px" }}>
                  <Slider centerMode={false} {...sliderSettings} adaptiveHeight>
                     <Box
                        sx={{
                           backgroundImage:
                              "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/inicio/Banner+HOME+1.png)",
                           backgroundSize: "cover",
                           backgroundPosition: "center",
                           width: "100%",
                           height: "950px",
                        }}
                     />
                     <Box
                        sx={{
                           backgroundImage:
                              "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/inicio/Banner+HOME+2.png)",
                           backgroundSize: "cover",
                           backgroundPosition: "center",
                           width: "100%",
                           height: "950px",
                        }}
                     />
                     <Box
                        sx={{
                           backgroundImage:
                              "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/inicio/Banner+HOME+3.png)",
                           backgroundSize: "cover",
                           backgroundPosition: "center",
                           width: "100%",
                           height: "950px",
                        }}
                     />
                     <Box
                        sx={{
                           backgroundImage:
                              "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/inicio/Banner+HOME+4.png)",
                           backgroundSize: "cover",
                           backgroundPosition: "center",
                           width: "100%",
                           height: "950px",
                        }}
                     />
                  </Slider>
               </Box>
               <NavBar />
               <Grid container>
                  <Grid
                     item
                     xs={12}
                     md={12}
                     sx={{ px: { xs: 5, sm: 7.5, md: 10 }, pt: { xs: 3, sm: 14 }, mt: "-870px" }}
                  >
                     <Slide in={true} direction="up" appear timeout={500}>
                        <div>
                           <Fade in={true} appear timeout={1200}>
                              <Typography
                                 color={"primary"}
                                 fontFamily={"bold"}
                                 sx={{ fontSize: { xs: "35px", md: "40px" }, lineHeight: 1.15, pb: 1.5 }}
                              >
                                 Legal Compliance System
                              </Typography>
                           </Fade>
                        </div>
                     </Slide>
                     {/* <Button onClick={() => i18n.changeLanguage("es")}>Espanol</Button>
            <Button onClick={() => i18n.changeLanguage("en")}>ingles</Button>*/}
                     <Box maxWidth={{ xs: "98%" }} mb={1.5}>
                        {" "}
                        {/* Añadir margin-bottom */}
                        <Typography
                           className="animate__animated animate__fadeInLeft"
                           textAlign={"left"}
                           color={"primary"}
                           fontWeight={800}
                           mt={1}
                           sx={{
                              fontSize: { xs: "20px", md: "18px" },
                              maxWidth: { xs: "100%" },
                              lineHeight: 1.2,
                           }}
                        >
                           La solución en materia de gestión de riesgo y cumplimiento legal y fiscal.
                        </Typography>
                     </Box>
                     <Box maxWidth={{ xs: "98%" }}>
                        {" "}
                        {/* Añadir margin-bottom */}
                        <Typography
                           className="animate__animated animate__fadeInLeft"
                           fontWeight={"bold"}
                           textAlign={"left"}
                           color={"primary"}
                           sx={{
                              fontSize: { xs: "15px", md: "16px" },
                              maxWidth: { xs: "100%" },
                              lineHeight: 1.2,
                           }}
                        >
                           Obtenga una sesión de consultoría gratuita para fortalecer su Gobierno Corporativo.
                        </Typography>
                     </Box>
                     <Box maxWidth={{ xs: "98%" }} mb={2}>
                        {" "}
                        {/* Añadir margin-bottom */}
                        <Typography
                           className="animate__animated animate__fadeInLeft"
                           fontWeight={"bold"}
                           textAlign={"left"}
                           color={"#FF4F5B"}
                           sx={{
                              fontSize: { xs: "15px", md: "16px" },
                              maxWidth: { xs: "100%" },
                              lineHeight: 1.2,
                           }}
                        >
                           Realice nuestro diagnóstico de Gobernanza:
                        </Typography>
                     </Box>
                     <Box sx={{ mt: { xs: 5, md: 2 }, display: "flex", gap: 2 }}>
                        <Button
                           href="/diagnostico-gobernanza-empresarial"
                           sx={{ borderRadius: 3, p: 1, "&:hover": { bgcolor: "#5c6c7c" } }}
                        >
                           <Box sx={{ display: "flex", alignItems: "center" }} mx={1}>
                              <Typography fontWeight={"bold"} lineHeight={1}>
                                 ¡Realizar ahora!
                              </Typography>
                           </Box>
                        </Button>
                        <Box sx={{ mt: "20px" }}>
                           <ContactConsultantButton />
                        </Box>
                     </Box>
                  </Grid>
               </Grid>
            </Box>
            <Box sx={{ px: { xs: 3, sm: 7, md: 2, lg: 10 }, bgcolor: "#F7F7F7" }}>
               <VideoContainerHome
                  sx={{}}
                  // subTitle={
                  //    <Typography
                  //       fontFamily={"bold"}
                  //       fontSize={"20px"}
                  //       textAlign={"left"}
                  //       sx={{
                  //          px: { sm: 2, md: 0 },

                  //          lineHeight: 1.2,
                  //          color: "#162C44",
                  //          display: "flex",
                  //          justifyContent: { xs: "center", sm: "center", md: "flex-start" },
                  //          width: "100%",
                  //       }}
                  //    >
                  //       Conozca a Lecosy
                  //    </Typography>
                  // }
                  videoUrl={
                     "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/inicio/Inicio+segunda+seccion.mp4"
                  }
                  videoPlayerProps={{
                     position: "relative",
                     width: "100%",

                     height: "100%",
                     paddingTop: "75%",
                     overflow: "hidden",
                  }}
                  direction="row-reverse"
                  subComponent={
                     <Box
                        item
                        sx={{
                           display: "flex",
                           justifyContent: { xs: "center", md: "flex-end" },
                           width: "100%",
                           mt: { xs: -4, sm: -6, md: -8 },
                           pb: { xs: 10, sm: 10, md: 0 },
                        }}
                     >
                        <Button
                           sx={{
                              borderRadius: 3,
                              px: 2,
                              "&:hover": { bgcolor: "#5c6c7c" },
                           }}
                           onClick={() => window.open("/nosotros", "_blank")}
                        >
                           <Typography fontFamily={"bold"}>Conózcanos</Typography>
                        </Button>
                     </Box>
                  }
                  alternComponent={
                     <Slide in={true} appear timeout={1500} direction="up">
                        <div>
                           <Fade in={true} appear timeout={1500}>
                              <Stack
                                 direction={"column"}
                                 spacing={3}
                                 px={{ xs: 2, sm: 2, md: 2, lg: 0 }}
                                 sx={{ pt: { md: 4, lg: 5 } }}
                              >
                                 <Typography
                                    fontSize={{ md: "16px", lg: "18px" }}
                                    textAlign={"justify"}
                                    lineHeight={1.3}
                                 >
                                    <strong>En Lecosy,</strong> entendemos que la combinación efectiva de cumplimiento
                                    normativo y Responsabilidad Corporativa es la clave para{" "}
                                    <strong> garantizar el éxito de cualquier organización.</strong>
                                 </Typography>
                                 <Typography
                                    fontSize={{ md: "16px", lg: "18px" }}
                                    textAlign={"justify"}
                                    lineHeight={1.3}
                                    pb={{ xs: 5, sm: 2 }}
                                 >
                                    Nuestro sistema se basa en la creación de un sistema único que facilita la gestión
                                    del cumplimiento normativo para mitigar riesgos legales y fiscales, expandir
                                    horizontes en nuevos mercados y asegurar la estabilidad y el crecimiento sostenible
                                    de las empresas.
                                 </Typography>
                                 <Typography
                                    color={"#162c44"}
                                    fontFamily={"Bold"}
                                    sx={{
                                       fontSize: { xs: "20px", md: "22px", lg: "26px" },
                                       textAlign: { xs: "center", sm: "center", md: "left" },
                                       lineHeight: 1.1,
                                       pb: { md: "100px" },
                                    }}
                                 >
                                    Gestione de manera eficiente sus obligaciones a través nuestro sistema de
                                    cumplimiento legal y fiscal.
                                 </Typography>
                              </Stack>
                           </Fade>
                        </div>
                     </Slide>
                  }
               />
            </Box>
            <Box
               sx={{
                  backgroundImage:
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/inicio/background/Fondo-seccion-4.jpg)",
                  backgroundSize: "cover",
                  backgroundAttachment: "fixed",
                  py: { xs: 10, md: 15 },
                  px: { lg: 30, md: 20, xs: 3 },
               }}
            >
               <Stack direction={"column"} spacing={2}>
                  <Typography
                     sx={{
                        color: "white",
                        fontFamily: "Bold",
                        fontSize: { xs: "25px", md: "35px" },
                        textAlign: "center",
                     }}
                  >
                     Sistema único en el mercado nacional.
                  </Typography>
                  <Typography
                     sx={{
                        color: "white",
                        fontSize: { xs: "15px", md: "25px" },
                        textAlign: "center",
                     }}
                  >
                     Con nuestro sistema, podrá cumplir con todas sus obligaciones normativas aplicables y al mismo
                     tiempo llevar un control eficiente de los riesgos en su organización.
                  </Typography>
                  <Box display={"flex"} justifyContent={"center"}>
                     <Button
                        target="_blank"
                        href="https://calendar.app.google/KcrgoEnbz9SJScGv8"
                        sx={{
                           bgcolor: "white",
                           color: "#162c44",
                           "&:hover": { bgcolor: "#8A95A1" },
                        }}
                     >
                        <Typography fontFamily={"bold"}>Solicite un DEMO</Typography>
                     </Button>
                  </Box>
               </Stack>
            </Box>
            <Box
               key={"Módulos"}
               sx={{
                  backgroundImage:
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/inicio/background/Fondo-izquierdo-seccion-5.png)",
                  backgroundSize: "40%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top left",
               }}
            >
               <Box
                  display={"flex"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  px={{ xs: 3, sm: 4, md: 10, lg: 6 }}
                  py={{ xs: 3, md: 5 }}
                  sx={{
                     backgroundImage:
                        "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/inicio/background/Fondo-derecho-seccion-5.png)",
                     backgroundSize: "40%",
                     backgroundRepeat: "no-repeat",
                     backgroundPosition: "bottom right",
                  }}
               >
                  <Typography fontFamily={"bold"} textAlign={"center"} sx={{ fontSize: { xs: "30px", md: "35px" } }}>
                     Conozca nuestros módulos
                  </Typography>
                  <Typography mt={3} textAlign={"center"} sx={{ fontSize: { xs: "15px", md: "20px" } }}>
                     Nuestros módulos son complementos al diseño y mantenimiento para un sistema de gestión de
                     cumplimiento legal y fiscal.
                  </Typography>
                  <Box
                     sx={{
                        width: { xs: "85%", sm: "100%" },
                        paddingTop: 2,
                        position: "relative",
                        maxWidth: "1070px",
                     }}
                  >
                     <Slider {...carouselSettings}>
                        {modulesInfo.map((module, index) => (
                           <Box key={index} sx={{ display: "flex", flexDirection: "column" }}>
                              {/* Nivel superior e inferior del carrusel */}
                              <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                                 <ModuleCard {...module} overflow="hidden" />
                              </Box>
                           </Box>
                        ))}
                     </Slider>
                     <Slider {...carouselSettings}>
                        {modulesInfo.map((module, index) => (
                           <Box key={index} sx={{ display: "flex", flexDirection: "column" }}>
                              <Box sx={{ display: "flex", justifyContent: "space-around", marginTop: 2 }}>
                                 <ModuleCard {...modulesInfoTwo[index]} overflow="hidden" />
                              </Box>
                           </Box>
                        ))}
                     </Slider>
                  </Box>

                  {/* <Box
                     sx={{
                        width: { xs: "85%", sm: "100%" },
                        paddingTop: 2,
                        position: "relative",
                        maxWidth: "1070px",
                     }}
                  >
                     <Slider {...carouselSettings}>
                        {modulesInfo.map((module) => {
                           return <ModuleCard {...module} key={module.title} overflow={"hidden"} />;
                        })}
                     </Slider>
                  </Box>
                  <Box
                     sx={{
                        width: { xs: "85%", sm: "100%" },
                        paddingTop: 2,
                        position: "relative",
                        maxWidth: "1070px",
                     }}
                  >
                     <Slider {...carouselSettings}>
                        {modulesInfoTwo.map((module) => {
                           return <ModuleCard {...module} key={module.title} overflow={"hidden"} />;
                        })}
                     </Slider>
                  </Box> */}
                  <Button
                     target="_blank"
                     href="https://drive.google.com/file/d/1AoSz8QQhz8ei_xBakYOyUiwKUGunzAIv/view?usp=sharing"
                     sx={{
                        borderRadius: 3,
                        py: 1,
                        px: 2,
                        "&:hover": { bgcolor: "#5c6c7c" },
                     }}
                  >
                     <Typography fontFamily={"bold"}>Descargar catálogo de soluciones</Typography>
                  </Button>
               </Box>
            </Box>
            <Box
               py={{ xs: 5, lg: 10 }}
               justifyContent={"center"}
               color={"white"}
               sx={{
                  backgroundImage:
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/inicio/background/34+1.png)",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
               }}
            >
               <AnimationOnScroll animateIn={"animate__zoomIn"} duration={0.75} animateOnce animatePreScroll={false}>
                  <Box display={"flex"} justifyContent={"center"} ref={bannerRef}>
                     <Typography
                        textAlign={"center"}
                        fontSize={"20px"}
                        maxWidth={{ xs: "90%", lg: "55%" }}
                        sx={{ filter: "drop-shadow(4px 2px 3px #000000)", lineHeight: 1.1, px: { xs: 2 } }}
                     >
                        Cada uno de nuestros módulos ha sido creado por expertos y especialistas en{" "}
                        <strong>Gestión de Riesgos, cumplimiento normativo y seguridad de la información.</strong>
                     </Typography>
                  </Box>
               </AnimationOnScroll>
            </Box>
            <BenefitCards />
            {/* seccion ocultada
            <Box display={"flex"} justifyContent={"center"} bgcolor={"#F7F7F7"}>
               <Typography
                  textAlign={"center"}
                  color={"primary"}
                  fontFamily={"bold"}
                  sx={{ fontSize: { xs: "30px", md: "35px" }, paddingTop: "60px" }}
               >
                  Conozca nuestras alianzas
               </Typography> 
            </Box>
            {ExternBlogSection(
               ["Legafirm", "EBM"],
               [
                  "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/blog/LEGAFIRM-logo.png",
                  "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/blog/EBM-logo.png",
               ],

               [
                  <Typography key={"message1"} textAlign={"center"} sx={{ fontSize: { xs: "20px", md: "25px" } }}>
                     Establece estándares de cumplimiento y lleva un{" "}
                     <strong>debido control dentro de tu organización.</strong>
                  </Typography>,
                  <Typography key={"message2"} textAlign={"center"} sx={{ fontSize: { xs: "20px", md: "25px" } }}>
                     Potencializamos la generación de <strong>valor y trascendencia</strong> de tu organización.
                  </Typography>,
               ],
               ["https://legafirm.com.mx/blog/", "https://ebmconsultores.com.mx/insights/"]
            )} */}
            <Box
               className="gradientBox90"
               sx={{
                  display: "flex",
                  flexDirection: { xs: "column", lg: "row" },
                  py: 10,
                  justifyContent: "space-evenly",
                  alignItems: "center",
               }}
            >
               <Stack direction={"column"} flex={1} sx={{ mx: { xs: 3, md: 8 }, mb: { xs: 3, lg: 0 } }}>
                  <Box display={"flex"} flexDirection={"column"}>
                     <Typography
                        sx={{
                           color: "white",
                           fontSize: { xs: "15px", md: "25px" },
                           textAlign: "center",
                        }}
                     >
                        Para una <strong>mejor experiencia</strong> y mayor información le recomendamos{" "}
                        <strong>visitar nuestro blog Lecosy.</strong>
                     </Typography>
                     <Button
                        href="https://blog.lecosy.com.mx"
                        target="_blank"
                        sx={{
                           alignSelf: "center",
                           bgcolor: "white",
                           color: "#162c44",
                           mt: 3,
                           px: 2,
                           "&:hover": { bgcolor: "#8A95A1" },
                        }}
                     >
                        <Typography
                           sx={{
                              textTransform: "none",
                              fontFamily: "Bold",
                              color: "primary",
                           }}
                        >
                           Visitar blog Lecosy
                        </Typography>
                     </Button>
                  </Box>
               </Stack>
               <Box
                  flex={2}
                  sx={{
                     bgcolor: "#F7F7F7",

                     borderTopLeftRadius: { xs: 0, md: "24px" },
                     borderBottomLeftRadius: { xs: 0, md: "24px" },
                     py: { xs: 8, md: 5 },
                  }}
               >
                  <Typography
                     textAlign={"center"}
                     color={"#162c44"}
                     fontWeight={"bold"}
                     sx={{ fontSize: { xs: "20px", md: "25px" } }}
                  >
                     Artículos más vistos
                  </Typography>
                  <Grid
                     container
                     mt={2}
                     sx={{
                        display: "flex",
                        pl: { sm: "2%", md: "5%", lg: "5%" },
                        pr: { sm: "2%", md: "5%", lg: "10%" },
                     }}
                  >
                     <>
                        {wpData.map((element, i) => {
                           return (
                              <Grid
                                 item
                                 xs={12}
                                 sm={4}
                                 md={4}
                                 key={element.children[i].value}
                                 alignSelf={"stretch"}
                                 justifyContent={"space-between"}
                                 display={"flex"}
                                 flexDirection={"column"}
                                 sx={{ px: { xs: 10, sm: 1, md: 4, lg: 2 }, pt: { xs: 3, md: 0 } }}
                              >
                                 <Box
                                    borderRadius={"20px"}
                                    alignSelf={"center"}
                                    component="img"
                                    src={getImageUrl(element.children[element.children.length - 2].value)}
                                    width={{ xs: "95%", sm: "100%" }}
                                 />
                                 <Stack alignContent={"center"} my={2}>
                                    <Typography
                                       fontWeight={"bold"}
                                       color={"#162c44"}
                                       textAlign={"center"}
                                       sx={{ fontSize: { xs: "18px", md: "16px" }, lineHeight: 1.1 }}
                                    >
                                       {`${replaceCharacterWithSpace(element.children[0].value.substring(0, 40))}...`}
                                    </Typography>
                                 </Stack>
                                 <Typography
                                    textAlign={"justify"}
                                    sx={{ fontSize: { xs: "16px", md: "14px" }, lineHeight: 1.2, px: { xs: 2, sm: 1 } }}
                                 >
                                    {getCroppedDescription(element.children[element.children.length - 1].value)}
                                 </Typography>
                                 <a
                                    href={element.children[1].value}
                                    rel="noreferrer"
                                    style={{
                                       textDecoration: "none",
                                       color: "#162c44",
                                    }}
                                 >
                                    <Typography
                                       textAlign={"center"}
                                       fontFamily={"bold"}
                                       sx={{
                                          pt: 1,
                                          "&:hover": { color: "#5c6c7c" },
                                          fontSize: { xs: "12px", md: "14px" },
                                       }}
                                    >
                                       Leer más
                                    </Typography>
                                 </a>
                              </Grid>
                           );
                        })}
                     </>
                  </Grid>
               </Box>
            </Box>
            <Box
               sx={{
                  backgroundImage:
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/inicio/background/7+1.png)",
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
               }}
            >
               <Box
                  sx={{
                     display: "flex",
                     flexDirection: { md: "row", xs: "column" },
                     justifyContent: "space-evenly",
                     alignItems: "center",
                     py: 10,
                     px: { xs: 3, md: 10 },
                     mb: { lg: 3 },
                  }}
               >
                  <Box
                     sx={{
                        flex: 1,
                        flexDirection: "column",
                     }}
                  >
                     <Typography
                        color={"primary"}
                        sx={{
                           fontFamily: "Bold",
                           fontSize: { xs: "30px", md: "40px" },
                           mb: 2,
                        }}
                     >
                        Escríbanos
                     </Typography>
                     <WriteUs isDivided={true} />
                  </Box>
                  <Box
                     sx={{
                        flex: 1,
                        width: { md: "40%" },
                        display: "flex",
                        justifyContent: { md: "center", xs: "right" },
                        pt: { xs: 5, md: 0 },
                     }}
                  >
                     <Typography
                        color={"primary"}
                        sx={{
                           fontSize: { xs: "30px", md: "40px" },
                           textAlign: { md: "right", xs: "center" },
                           fontFamily: "Bold",
                           lineHeight: 1.4,
                        }}
                     >
                        Prevenga, corrija y elimine posibles riesgos.
                     </Typography>
                  </Box>
               </Box>
            </Box>
            <Box
               sx={{
                  backgroundImage:
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/Figures/Figura-5.png)",
                  backgroundSize: { xs: "0% 0%", md: "55% 100%" },
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "bottom right",
               }}
            >
               <Typography
                  fontFamily={"bold"}
                  color={"primary"}
                  sx={{
                     px: { xs: 3, md: 10 },
                     pt: 5,
                     fontSize: { xs: "30px", md: "40px" },
                  }}
               >
                  Preguntas frecuentes
               </Typography>
               {QuestionAccordion(homeQuestions)}
            </Box>
            <ComplaintPlatformButton />
            {ScrollToTopButton(window)}
            <ContactFloatingButton />
            <Footer />
         </ScrollToTop>
      </ThemeConfig>
   );
};
