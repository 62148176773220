import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";

const WhatIsServiceComponent = ({ what, why, objective, hasObjective = false, imageUrl, fullHeight = false }) => {
   return (
      <Stack
         direction={{ xs: "column-reverse", lg: hasObjective ? "row-reverse" : "row" }}
         sx={{ bgcolor: "#46566A", display: "flex", minHeight: "600px" }}
      >
         <Stack direction={"column"} flex={1}>
            {hasObjective ? undefined : (
               <Box
                  width={{ xs: "100%", lg: "20%" }}
                  height={{ xs: "600px", md: "400px", lg: "675px" }}
                  position={"absolute"}
                  right={{ xs: "0", lg: "50%" }}
                  sx={{
                     backgroundImage:
                        "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/gobierno-corporativo/fondos/Fondo-Seccion-3-derecho.png)",
                     backgroundRepeat: "no-repeat",
                     backgroundSize: { xs: "300px 300px", lg: "350px 350px" },
                     backgroundPosition: "bottom right",
                     opacity: 0.15,
                  }}
               />
            )}

            {hasObjective ? (
               <Box
                  flex={1}
                  pt={{ xs: 10, md: 20 }}
                  pb={10}
                  mt={10}
                  px={{ xs: 2, sm: 5, lg: 10 }}
                  bgcolor={"#748190"}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
               >
                  <Typography
                     fontFamily={"bold"}
                     color={"white"}
                     lineHeight={1} // Ajusta el interlineado aquí
                     sx={{ mb: 2, px: { xs: 3, md: 15, lg: 1 }, fontSize: { xs: "30px", md: "40px" } }}
                  >
                     ¿Cuáles son las obligaciones a las que se encuentran sujetas las empresas?
                  </Typography>
                  <Typography color={"white"} textAlign={"justify"} sx={{ px: { xs: 3, sm: 4, md: 15, lg: 1 } }}>
                     {objective}
                  </Typography>
                  <Box
                     width={{ xs: "100%", md: "100%", lg: "20%" }}
                     height={{ xs: "930px", sm: "640px", md: "610px", lg: "784px" }}
                     position={"absolute"}
                     right={{ xs: "0%", lg: "0%" }}
                     sx={{
                        //maxHeight: "600px",
                        backgroundImage:
                           "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/gobierno-corporativo/fondos/Fondo-Seccion-3-derecho.png)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: { xs: "300px 300px", lg: "350px 350px" },
                        backgroundPosition: "bottom right",
                        opacity: 0.15,
                     }}
                  ></Box>
               </Box>
            ) : undefined}
         </Stack>
         <Box flex={1}>
            <Box height={"100%"} px={0} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
               {!hasObjective ? undefined : (
                  <Box
                     width={{ xs: "100%", lg: "20%" }}
                     height={{ xs: "600px", md: "400px", lg: "675px" }}
                     position={"absolute"}
                     right={{ xs: "0", lg: "50%" }}

                     /* sx={{
                        backgroundImage:
                           "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/gobierno-corporativo/fondos/Fondo-Seccion-3-derecho.png)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: { xs: "300px 300px", lg: "350px 350px" },
                        backgroundPosition: "bottom right",
                        opacity: 0.15,
                     }} */
                  />
               )}
               <Box
                  mt={{ lg: 19 }}
                  sx={{
                     width: "100%",
                     height: fullHeight ? "100%" : { xs: "350px", lg: "400px" },
                     backgroundImage: imageUrl,
                     backgroundSize: "cover",
                     backgroundRepeat: "no-repeat",
                     backgroundPosition: "top center",
                  }}
               />

               <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  py={"5%"}
                  sx={{ px: { xs: 5, sm: 8, md: 16, lg: 10 } }}
               >
                  <Typography
                     fontFamily={"bold"}
                     color={"white"}
                     sx={{ fontSize: { xs: "30px", md: "40px" }, textAlign: { xs: "center", lg: "left" } }}
                  >
                     ¿Qué es?
                  </Typography>
                  <Typography color={"white"} textAlign={"justify"}>
                     {what}
                  </Typography>
                  {!hasObjective ? (
                     <div>
                        <Typography
                           fontFamily={"bold"}
                           color={"white"}
                           sx={{
                              py: 2,
                              mt: 2,
                              fontSize: { xs: "30px", md: "40px" },
                              textAlign: { xs: "center", md: "left" },
                           }}
                        >
                           ¿Por qué contar con él?
                        </Typography>
                        <Typography color={"white"} textAlign={"justify"}>
                           {why}
                        </Typography>
                     </div>
                  ) : undefined}
               </Box>
            </Box>
         </Box>
      </Stack>
   );
};

WhatIsServiceComponent.propTypes = {
   what: PropTypes.string.isRequired,
   why: PropTypes.string,
   objective: PropTypes.string,
   hasObjective: PropTypes.bool,
   imageUrl: PropTypes.string,
   fullHeight: PropTypes.bool,
};

export default WhatIsServiceComponent;
