import { Typography } from "@mui/material";

const FutureModules = [
   {
      icon: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/iconos/ICONOS+de+m%C3%B3dulos+LECOSY_Programa+de+cumplimiento.svg)",
      title: "Programa de Cumplimiento",
      description: (
         <Typography color="primary" sx={{ textAlign: "justify", fontSize: 16 }}>
            En el presente módulo podremos definir los factores que intervienen en el contexto de la organización, como
            lo es el legal, financiero, operacional, organizacional, planeación estratégica, seguridad en la
            información, entre otros, otorgándole a ésta una, guía de cumplimiento, un grado de cumplimiento y las
            herramientas para cumplir con sus obligaciones legales y fiscales, así como otras funcionalidades, que nos
            permitirán generar reportes de cumplimiento y visualizar el avance gradual de la regularización del control
            interno de la organización.
         </Typography>
      ),
      image: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/proximo-servicio/image+9.png",
   },
   {
      icon: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/iconos/ICONOS+de+m%C3%B3dulos+LECOSY_Gesti%C3%B3n+de+riesgos.svg)",
      title: "Gestión de Riesgos",
      description: (
         <Typography color="primary" sx={{ textAlign: "justify", fontSize: 16 }}>
            El módulo de Gestión de Riesgos será un complemento clave del Programa de Cumplimiento, ya que permite
            identificar, evaluar y controlar los riesgos que pueden afectar el desempeño de la organización. Este
            permite gestionar las acciones necesarias para reducir y/o mitigar los riesgos legales y fiscales, así como
            realizar el monitoreo y seguimiento correspondiente de éstos.
         </Typography>
      ),
      image: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/proximo-servicio/image+10.png",
   },
   {
      icon: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/iconos/ICONOS+de+m%C3%B3dulos+LECOSY_Correlaci%C3%B3n+de+leyes.svg)",
      title: "Correlación de Leyes",
      description: (
         <Typography color="primary" sx={{ textAlign: "justify", fontSize: 16 }}>
            El módulo de Correlación de Leyes permitirá al usuario realizar un análisis, comparación y relación entre
            diferentes legislaciones respecto a un tema en específico, siendo así, una herramienta de búsqueda e
            investigación jurídica.
         </Typography>
      ),
      image: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/proximo-servicio/image+11.png",
   },
   {
      icon: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/iconos/ICONOS+de+m%C3%B3dulos+LECOSY_Auditor%C3%ADa+interna+.svg)",
      title: "Auditoría Interna",
      description: (
         <Typography color="primary" sx={{ textAlign: "justify", fontSize: 16 }}>
            El módulo de Auditoría Interna es el segundo complemento del Programa de Cumplimiento, ya que las
            funcionalidades del mismo permiten realizar una evaluación exhaustiva que permite mejorar sus procesos y
            prácticas internas a través de la identificación y corrección de problemas, errores y áreas de mejora.
         </Typography>
      ),
      image: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/prox-sol-au-in.png",
   },
   {
      icon: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/iconos/ICONOS+de+m%C3%B3dulos+LECOSY_Responsabilidad+Corporativa+copia.svg)",
      title: (
         <Typography
            color={"primary"}
            sx={{
               fontFamily: "ExtraBold",
               fontSize: { xs: "18px", sm: "1.5rem" },
               textAlign: { xs: "center", md: "left" },
               letterSpacing: 0.5,
               lineHeight: 1.1,
               mt: { md: "-30px", lg: "-60px" },
               px: { xs: 4 },
               pt: { lg: 2 },
            }}
         >
            Responsabilidad Corporativa
         </Typography>
      ),
      description: (
         <Typography color="primary" sx={{ textAlign: "justify", fontSize: 16 }}>
            El módulo impulsa la Responsabilidad Corporativa en todos los frentes, enfocándose en su relevancia y los
            beneficios que aporta. Con nuestro módulo, ayudamos a desarrollar y cumplir con políticas claras y sólidas,
            abarcando aspectos críticos como el cumplimiento normativo y la gestión ambiental, estableciendo indicadores
            y métricas precisas para medir y evaluar su desempeño, promoviendo la transparencia en su rendición de
            cuentas.
         </Typography>
      ),
      image: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/prox-sol-res-corp.png",
   },
];

export default FutureModules;
