import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Container from "@mui/material/Container";
import ReactPlayer from "react-player";

const ServiceScreenVideo = (sysmodules) => {
   const playerRef = useRef(null);
   const [isPlaying, setIsPlaying] = useState(false);

   useState(false);
   useEffect(() => {
      const observe = new IntersectionObserver(
         ([entry]) => {
            setIsPlaying(entry.isIntersecting);
         },
         {
            root: null, //observa el viewport completo
            threshold: 0.5, //Se activara cuando el 50% del video este en pantalla
         }
      );
      if (playerRef.current) {
         observe.observe(playerRef.current);
      }
      return () => {
         if (playerRef.current) {
            observe.unobserve(playerRef.current);
         }
      };
   }, []);
   return (
      <Container
         ref={playerRef}
         sx={{
            my: { sx: 0, md: 6 },
            mb: { xs: 1.5, md: 1.5, lg: 1.5 },
            width: { xs: "300px", sm: "400px", md: "380px", lg: "500px" },
            aspectRatio: "16/9",
            overflow: "hidden",
            position: "relative",
            mx: 2,
         }}
      >
         <ReactPlayer
            className="player"
            playing={isPlaying}
            url={sysmodules.videoUrl}
            muted
            loop
            width={"100%"}
            height={"100%"}
            style={{
               position: "absolute",
               top: 0,
               left: 0,
            }}
         />
      </Container>
   );
};
export default ServiceScreenVideo;
export const descriptionZone = (sysmodules, i) => {
   return (
      <Box
         sx={{
            flexDirection: "column",
            flex: 1,

            px: { xs: 1, sm: 6, md: 0 },
            py: { xs: 2, sm: 3, md: 0 },
         }}
      >
         <Grid
            container
            direction={i % 2 === 0 ? "row" : "row-reverse"}
            spacing={{ lg: 1 }}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
         >
            <Grid
               item
               xs={12}
               sx={{
                  maxWidth: {
                     md: "420px",
                     lg: "550px",
                  },
               }}
            >
               <Box
                  sx={{
                     px: { sm: 2, md: 5, lg: 6 },
                  }}
               >
                  <Typography
                     color={"primary"}
                     sx={{
                        fontFamily: "ExtraBold",
                        fontSize: "1.2rem",
                        textAlign: { xs: "center", md: "left" },
                        letterSpacing: 0.5,
                        lineHeight: 1.2,
                        mt: { sm: 4, md: 0 },
                     }}
                  >
                     {sysmodules.title}
                  </Typography>
                  <AnimationOnScroll
                     animateIn="animate__slideInLeft"
                     animateOnce
                     animatePreScroll={false}
                     duration={0.75}
                  >
                     <Divider sx={{ borderBottomWidth: "4px", bgcolor: "#162c44", my: 1 }} />
                     {sysmodules.description}
                     {sysmodules.list}
                  </AnimationOnScroll>
               </Box>
            </Grid>
         </Grid>
      </Box>
   );
};
